import React, { useContext, useState, useEffect, useRef, Component } from 'react';
import './ProjectDataEntry.scss';
import * as API from '../api/api';
import { DatePicker, Select, Space, Table, Input, Button, Popconfirm, Form, InputNumber } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

const { Option } = Select;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

//
class ProjectDataEntry extends Component {

  constructor(props) {
    super();
    this.state = {
      company: 'QL',
      job: '',
      dataSource: [],
      count: 0,
      jobs: []
    };

    this.columns = [
      {
        title: 'Claim Date',
        dataIndex: 'claim_date',
        width: '30%',
        editable: true,
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <DatePicker
              picker="day"
              onChange={(dt, dtStr) => {
                this.handleUpdate(record.id, {...record, claim_date: moment(dtStr, 'YYYY-MM-DD')._i});
              }}
              defaultValue={moment(record.claim_date, moment.ISO_8601)}
              />
          ) : null
      },
      {
        title: 'Terms',
        dataIndex: 'terms',
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select style={{ width: '150px' }} value={record.term} onChange={(update) => {
              this.handleUpdate(record.id, {...record, term: update});
            }}>
              <Option value="30_DAYS_EOM">30 DAYS EOM</Option>
              <Option value="7_DAYS_NET">7 DAYS NET</Option>
              <Option value="14_DAYS_NET">14 DAYS NET</Option>
              <Option value="21_DAYS_NET">21 DAYS NET</Option>
              <Option value="45_DAYS_NET">45 DAYS NET</Option>
              <Option value="60_DAYS_NET">60 DAYS NET</Option>
            </Select>
          ) : null
      },
      {
        title: 'AR Projected INCL GST',
        dataIndex: 'ar_projeced',
        render: (_, record) => <InputNumber
          value={record.ar_projected}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => {
            //Since this triggers a lot, we will update local state too.
            let dataSourceIndex = this.state.dataSource.findIndex((i) => i.id === record.id);
            let newDataSource = this.state.dataSource;
            newDataSource[dataSourceIndex].ar_projected = value;
            this.setState({
              ...this.state,
              dataSource: newDataSource
            });
            this.handleUpdate(record.id, newDataSource[dataSourceIndex]);
          }}
        />
      },
      {
        title: 'AP Projected INCL GST',
        dataIndex: 'ap_projeced',
        render: (_, record) => <InputNumber
          defaultValue={isNaN(record.ap_projected) ? 0 : Math.abs(parseInt(record.ap_projected))}
          formatter={value => `-$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\-\$\s?|(,*)/g, '')}
          min={0}
          onChange={(value) => {
            //Since this triggers a lot, we will update local state too.
            let dataSourceIndex = this.state.dataSource.findIndex((i) => i.id === record.id);
            let newDataSource = this.state.dataSource;
            newDataSource[dataSourceIndex].ap_projected = value;
            this.setState({
              ...this.state,
              dataSource: newDataSource
            });
            this.handleUpdate(record.id, newDataSource[dataSourceIndex]);
          }}
        />
      },
      {
        title: 'Claim Produced',
        dataIndex: 'claim_produced',
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select style={{ width: '150px' }} value={record.claim_produced ? 'Y' : 'N'} onChange={(update) => {
              this.handleUpdate(record.id, {...record, claim_produced: update});
            }}>
              <Option value="Y">Yes</Option>
              <Option value="N">No</Option>
            </Select>
          ) : null
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
      },
    ];

    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.changedCompany = this.changedCompany.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadJobs();
  }

  loadJobs(company) {
    API.getJobs(company || this.state.company).then((res) => {
      this.setState({
        ...this.state,
        jobs: res
      })
    });
  }

  loadData(job) {
    API.getProjectData(this.state.company, job || this.state.job).then(res => {
      this.setState({
        ...this.state,
        dataSource: res.data,
        count: res?.data?.length || 0
      })
    })
  }

  handleDelete(uuid) {
    API.deleteClaimRecord(uuid).then((res) => {
      this.loadData();
    })
  }

  handleUpdate(uuid, record) {
    API.updateClaimRecord(uuid, record).then((res) => {
      this.loadData();
    })
  }

  changedCompany(company) {
    this.setState({
      ...this.state,
      company,
      job: '',
      dataSource: [],
      count: 0
    })
    this.loadJobs(company);
  }

  // Top bar
  onChange(value) {
    this.setState({
      ...this.state,
      job: value
    })
    this.loadData(value);
  }

  handleAdd() {
    API.addClaimRecord(this.state.company, this.state.job).then((res) => {
      this.loadData();
    })
  }

  render () {
      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };
      return <div className="projectEntry">
        <div className="edit">
          <Space direction="horizontal" size={12}>
            <Select style={{ width: '150px' }} value={this.state.company} onChange={this.changedCompany}>
              <Option value="QL">Queensland</Option>
              <Option value="VI">Victoria</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 500 }}
              placeholder="Select a Project"
              optionFilterProp="children"
              onChange={this.onChange}
            >
              {this.state.jobs.map((i) => <Option value={i.job_number}>{i.title}</Option>)}
            </Select>
          </Space>
        </div>
        {this.state.job !== '' && <div className="claims">
          <h2>Claims</h2>
          <div>
            <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
              Add Claim
            </Button>
            <Table
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={this.state.dataSource}
              columns={this.columns}
            />
          </div>
        </div>}
        </div>
  }

}
 

export default ProjectDataEntry;
