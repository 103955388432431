
import React, { Component }  from "react";
import './Users.scss';
import { Space, Table, Select, Popconfirm, Input, message, Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import './Users.scss';
import 'antd/dist/antd.css';
import * as API from '../api/api';
import moment from "moment";

const { Option } = Select;


class Users extends Component {

  constructor(props) {
    super();
    this.state = {
      users: [],
      isModalVisible: false,
      accountEmail: '',
      accountType: 'internal',
      accountPassword: '',
      errorMessage: '',
      roles: []
    };

    this.getSettingsData = this.getSettingsData.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.addUser = this.addUser.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changedAccountType = this.changedAccountType.bind(this);
    this.changedAccountEmail = this.changedAccountEmail.bind(this);
    this.changedAccountPassword = this.changedAccountPassword.bind(this);
  }

  componentDidMount() {
    this.getSettingsData();
  }

  getSettingsData() {
    API.getAllUsers().then((res) => {
        this.setState({
            ...this.state,
            users: res.users,
            roles: res.roles
        });
    })
  }

  updateUser(user, field) {
    API.updateUsers(user, field).then((res) => {
      this.getSettingsData();
    })
  }

  deleteUser(email) {
    API.deleteUser(email).then((res) => {
      this.getSettingsData();
    })
  }

  addUser(email, accountType, accountPassword) {
    API.addUser(email, accountType, accountPassword).then((res) => {
      if(res && res.hasOwnProperty('err')) {
        if(!res.err) {
          message.success('User added.');
        } else {  
          message.error(res.err);
        }
      }
      this.getSettingsData();
    })
  }

  handleOk() {
    if(this.state.accountEmail === '') {
      this.setState({
        errorMessage: 'Please provide an email address.'
      });
    } else if(this.state.accountType !== 'internal' && this.state.accountPassword.length < 8) {
      this.setState({
        errorMessage: 'Password must be at least 8 characters.'
      });
    } else {
      this.setState({
        isModalVisible: false
      });
      this.addUser(this.state.accountEmail, this.state.accountType, this.state.accountPassword);
    }
  }

  handleCancel() {
    this.setState({isModalVisible: false, accountEmail: '', accountType: 'internal', accountPassword: '', errorMessage: ''});
  }

  showModal() {
    this.setState({isModalVisible: true, accountEmail: '', accountType: 'internal', accountPassword: '', errorMessage: ''});
  }

  changedAccountType(accountType) {
    this.setState({accountType});
  }

  changedAccountEmail(e) {
    this.setState({accountEmail: e.target.value});
  }

  changedAccountPassword(e) {
    this.setState({accountPassword: e.target.value});
  }

  render () {
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: text => text === 'liam.gibson@griffithuni.edu.au' ? 'me@liamgibson.com.au' : text
      },
      { title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Queensland',
        dataIndex: 'qld',
        key: 'qld',
        render: (_, record) =>
        this.state.users.length >= 1 ? (
          <Select style={{ width: '200px' }} value={record.QLD} disabled={record.scope === 'WRITE.*'} onChange={(update) => {
            this.updateUser(record.email, {qld: update});
          }}>
            {this.state.roles.map(role => <Option value={role.id} disabled={role.id == 0}>{role.display}</Option>)}
          </Select>
        ) : null
      },
      {
        title: 'Victoria',
        dataIndex: 'vic',
        key: 'vic',
        render: (_, record) =>
        this.state.users.length >= 1 ? (
          <Select style={{ width: '200px' }} value={record.VIC} disabled={record.scope === 'WRITE.*'} onChange={(update) => {
            this.updateUser(record.email, {vic: update});
          }}>
            {this.state.roles.map(role => <Option value={role.id} disabled={role.id == 0}>{role.display}</Option>)}
          </Select>
        ) : null
      },
      { title: 'Last Login',
        dataIndex: 'last_login',
        key: 'last_login',
        render: (_, record) => record.last_login ? moment(record.last_login, moment.ISO_8601).format('LLL') : 'Unknown'
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
              {record.scope === 'WRITE.*' ? '' : <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => this.deleteUser(record.email)}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">Delete User</a>
              </Popconfirm>}
          </Space>
        )
      }
    ]
    return <div className="Users">
        <div className="addUser">
        <Button type="primary" onClick={this.showModal}>
          Add User
        </Button>
        </div>
        <Table dataSource={this.state.users} columns={columns}  pagination={{defaultPageSize: 30}}/>
        <Modal title="Add User" visible={this.state.isModalVisible} okText="Add User" onOk={this.handleOk} onCancel={this.handleCancel}>
          <h5>Email</h5>
          <Input placeholder="user@icmco.com.au" value={this.state.accountEmail} onChange={this.changedAccountEmail}/>
          <br></br>
          <h4>Account Type</h4>
          <Select defaultValue="internal" style={{ width: '100%' }} value={this.state.accountType} onChange={this.changedAccountType}>
            <Option value="internal">Internal (Microsoft Auth)</Option>
            <Option value="external">External (Password Auth)</Option>
          </Select>
          {this.state.accountType !== 'internal' && <React.Fragment>
            <h4>Account Password</h4>
            <Input type="password" placeholder="Password" value={this.state.accountPassword} onChange={this.changedAccountPassword}/>
          </React.Fragment>
            }
          <p className="errMsg">{this.state.errorMessage}</p>
        </Modal>
    </div>
  }

}
 

export default Users;
