import {React, useState, useLayoutEffect} from "react";
import { Button } from "antd";
import './RiskOpportunitySummary.scss';
import * as API from '../api/api';

function RiskOpportunitySummary(props) {
    const [riskData, setRiskData] = useState([]);
    const [opportunityData, setOpportunityData] = useState([]);

    useLayoutEffect(() => {
        API.getSumRisk(props.company, props.job).then(res => {
            setRiskData(res.resData);
            
        })
        API.getSumOpportunity(props.company, props.job).then(res => {
            setOpportunityData(res.resData); 
        })
    }, [props.company, props.job])

    const updateRenderedData = () => {
        API.getSumRisk(props.company, props.job).then(res => {
            setRiskData(res.resData);
            
        })
        API.getSumOpportunity(props.company, props.job).then(res => {
            setOpportunityData(res.resData); 
        })
    }

    return(
        <div>
            <Button className='refresh-button' onClick={() => updateRenderedData()}>Refresh Data</Button>
            <table className="JRO-table">
                <tr>
                    <td><span></span></td>
                    <td><span>Worst</span></td>
                    <td><span>Likely</span></td>
                    <td><span>Best</span></td>
                </tr>
                {Object.keys(riskData).map(value => {
                    return (
                        <tr>
                            <td><span className="col-header">Risk</span></td>
                            <td><span>$ {riskData[value].worst}</span></td>
                            <td><span>$ {riskData[value].likely}</span></td>
                            <td><span>$ {riskData[value].best}</span></td>
                        </tr>
                    )
                })}
                {Object.keys(opportunityData).map(value => {
                    return(
                        <tr>
                            <td><span className="col-header">Opportunity</span></td>
                            <td><span>$ {opportunityData[value].worst}</span></td>
                            <td><span>$ {opportunityData[value].likely}</span></td>
                            <td><span>$ {opportunityData[value].best}</span></td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default RiskOpportunitySummary