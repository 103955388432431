import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as API from '../api/api';
import './JobCostSummary.scss'
import { Pie } from '@ant-design/plots';
import { Select, DatePicker } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function JobCostSummary() {
    // Get/Set State Hooks:
    const [secSumData, setSecSumData] = useState([]);
    const [histSecSumData, setHistSecSumData] = useState([]);
    const [countData, setCountData] = useState([]);
    const [histCountData, setHistCountData] = useState([]);
    const [statViaSectData, setStatViaSectData] = useState([]);
    const [histStatViaSectData, setHistStatViaSectData] = useState([]);
    const [initialDate, setInitialDate] = useState();
    const [finalDate, setFinalDate] = useState();

    useLayoutEffect(() => {
        API.getTenderStatusCount().then(countRes => {
            countRes.count.forEach(val => {
                let sum = parseInt(val.num);
                if (isNaN(sum)) sum = 0;
                val.num = sum;
            })
            setCountData(countRes.count);
        })

        API.getTenderTypeSub().then(typeRes => {
            typeRes.type.forEach(val => {
                let sum = parseFloat(val.subval);
                if (isNaN(sum)) sum = 0;
                val.subval = sum;
            })
            setSecSumData(typeRes.type);
        })

        API.getTenderSectViaStatus("WIP").then(typeRes => {
            if(!typeRes.type) return <span>No Data Available!</span>
            typeRes.type.forEach(val => {
                let sum = parseFloat(val.subval);
                if (isNaN(sum)) sum = 0;
                val.subval = sum;
            })
            setStatViaSectData(typeRes.type);
        })

    }, []);

    const handleDatePick = ([initDate, finalDate], [initDateStr, finalDateStr]) => {
        let financialStart = initDateStr;
        let financialEnd = finalDateStr;
        // transform to parsable string in YYYY-MM-DD format:
        financialStart = financialStart.concat("-07-01");
        financialEnd = financialEnd.concat("-06-30");

        setInitialDate(financialStart);
        setFinalDate(financialEnd);

        // Get data for Amount Submitted via Sector (Historical)
        API.getTenderTypeSubHistorical(financialStart, financialEnd).then(typeRes => {
            if (typeRes.type === "No Data!") {
                return setHistSecSumData(typeRes.type);
            } else {
                typeRes.type.forEach(val => {
                    let sum = parseInt(val.subval);
                    if (isNaN(sum)) sum = 0;
                    val.subval = sum;
                })
                setHistSecSumData(typeRes.type)
            }
        })

        // Get data for Total Job Status (Historical)
        API.getTenderStatusCountHistorical(financialStart, financialEnd).then(countRes => {
            if (countRes.count === "No Data!") {
                return setHistCountData(countRes.count);
            } else {
                countRes.count.forEach(val => {
                    let sum = parseInt(val.num);
                    if (isNaN(sum)) sum = 0;
                    val.num = sum;
                })
                setHistCountData(countRes.count);
            }
        })

        // get data for hist subvalue by type (testing):
        API.getTenderSectViaStatusHistorical("WIP", financialStart, financialEnd).then(res => {
            console.log(res.type);
        })
    }

    // Status Summary Functions:
    const StatusSummaryGraph = () => {
        if (countData.length === 0) {
            return <span>Loading ...</span>
        }
        const data = countData

        const config = {
            autoFit: true,
            appendPadding: 10,
            data,
            angleField: 'num',
            colorField: 'status',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
          };

          return <Pie {...config} />;
    }

    // Sector Summary Functions:
    const SectorSummaryGraph = () => {
        if (secSumData.length === 0) {
            return <span>Loading ...</span>
        }
        const data = secSumData;

        const config = {
            autoFit: true,
            appendPadding: 10,
            data,
            angleField: 'subval',
            colorField: 'type',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
          };

          return <Pie {...config} />;
    }
     
    // Sector via Status Summary Functions:
    const SectorViaStatusSummaryGraph = () => {
        if (statViaSectData.length === 0) {
            return <span>Loading...</span>
        }
        const data = statViaSectData;

        const config = {
            appendPadding: 10,
            data,
            angleField: 'subval',
            colorField: 'type',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
            autoFit: true,
          };

          return <Pie {...config} />;
    }

    // Historical Sector Summary Functions: 
    const SectorSummaryHistGraph = () => {
        if (histSecSumData === "No Data!") {
            return <div className='data-err'>
                <span>{<FontAwesomeIcon className='err-icon' size='6x' icon={faExclamationTriangle}/>}</span>
                <span className='err-msg'>No Data Present!</span>
                <span className='err-msg-secondary'>Please Select a Different Period!</span>
            </div>
        }
        const data = histSecSumData

        const config = {
            autoFit: true,
            appendPadding: 10,
            data,
            angleField: 'subval',
            colorField: 'type',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
          };

          return <Pie {...config} />;
    }

    // Historical Status Summary Functions:
    const StatusSummaryHistGraph = () => {
        if (histCountData === "No Data!") {
            return <div className='data-err'>
                <span>{<FontAwesomeIcon className='err-icon' size='6x' icon={faExclamationTriangle}/>}</span>
                <span className='err-msg'>No Data Present!</span>
                <span className='err-msg-secondary'>Please Select a Different Period!</span>
            </div>
        }
        const data = histCountData;

        const config = {
            autoFit: true,
            appendPadding: 10,
            data,
            angleField: 'num',
            colorField: 'status',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
          };

          return <Pie {...config} />;
    }

    // Historical Sector via Status Summary Functions: 
    const SectorViaStatusSummaryHistGraph = () => {
        if (histCountData === "No Data!") {
            return <div className='data-err'>
                <span>{<FontAwesomeIcon className='err-icon' size='6x' icon={faExclamationTriangle}/>}</span>
                <span className='err-msg'>No Data Present!</span>
                <span className='err-msg-secondary'>Please Select a Different Period!</span>
            </div>
        }
        const data = histStatViaSectData;
        console.log(data);

        const config = {
            autoFit: true,
            appendPadding: 10,
            data,
            angleField: 'subval',
            colorField: 'type',
            radius: 0.75,
            label: {
              type: 'spider',
              labelHeight: 28,
              content: '{name}\n{value}',
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
          };

          return <Pie {...config} />;
    }


    return(
        <div>
            <div className='primary-graphs'>
                <div className='graph-container'>
                    <h3 className='graph-title'>Total Amount Submitted via Sector</h3>
                    <SectorSummaryGraph />
                </div>
                <div className='graph-container'>
                    <h3 className='graph-title'>Total Job Status</h3>
                    <StatusSummaryGraph />
                </div>
            </div>

            <div className='secondary-graphs'>
                <div className='graph-container'>
                    <h3 className='graph-title'>Total Amount Submitted via Sector Sorted by Status</h3>
                    <Select  
                        className='content-selector'
                        defaultValue={"WIP"}
                        style={{minWidth: 100}} 
                        onChange={(value) => {
                            API.getTenderSectViaStatus(value).then(typeRes => {
                                if(!typeRes.type) return <span>No Data Available!</span>
                                typeRes.type.forEach(val => {
                                    let sum = parseFloat(val.subval);
                                    if (isNaN(sum)) sum = 0;
                                    val.subval = sum;
                                })
                                setStatViaSectData(typeRes.type);
                            })
                        }}
                    >
                        <Option value="WIP">WIP</Option>
                        <Option value="Submitted">Submitted</Option>
                        <Option value="Won">Won</Option>
                        <Option value="Lost">Lost</Option>
                        <Option value="Withdrawn">Withdrawn</Option>
                        <Option value="Other - Add Note">Other - Add Note</Option>
                    </Select>
                    <SectorViaStatusSummaryGraph />
                </div>
            </div>
            <div className='hist-section'>
                <h2 className='hist-title'>Select Historical Data</h2>
                <RangePicker 
                    className='content-selector'
                    picker="year"
                    allowClear={false}
                    allowEmpty= {[false, false]}
                    onChange={handleDatePick}
                />
            </div>
            <div className='primary-graphs'>
                <div className='graph-container'>
                    <h3 className='graph-title'>Total Amount Submitted via Sector between {initialDate} and {finalDate}</h3>
                        <SectorSummaryHistGraph />
                </div>
                <div className='graph-container'>
                    <h3 className='graph-title'>Job Status between {initialDate} and {finalDate}</h3>
                        <StatusSummaryHistGraph />
                </div>
            </div>
            <div className='secondary-graphs'>
                <div className='graph-container'>
                    <h3 className='graph-title'>Total Amount Submitted via Type between {initialDate} and {finalDate}</h3>
                    <Select  
                        className='content-selector'
                        defaultValue={"WIP"}
                        style={{minWidth: 100}} 
                        onChange={(value) => {
                            API.getTenderSectViaStatusHistorical(value, initialDate, finalDate).then(typeRes => {
                                if (typeRes.type === "No Data!") {
                                    return setHistStatViaSectData(typeRes.type);
                                } else {
                                    typeRes.type.forEach(val => {
                                    let sum = parseFloat(val.subval);
                                    if (isNaN(sum)) sum = 0;
                                    val.subval = sum;
                                    });
                                    setHistStatViaSectData(typeRes.type);
                                }
                            })
                        }}
                    >
                        <Option value="WIP">WIP</Option>
                        <Option value="Submitted">Submitted</Option>
                        <Option value="Won">Won</Option>
                        <Option value="Lost">Lost</Option>
                        <Option value="Withdrawn">Withdrawn</Option>
                        <Option value="Other - Add Note">Other - Add Note</Option>
                    </Select>
                    <SectorViaStatusSummaryHistGraph/>
                </div>
            </div>
        </div>
    ) 

}