import { Component } from "react";
import moment from 'moment';
import React from 'react';
import './Home.scss';
import { Bar } from 'react-chartjs-2';
import * as API from '../api/api';
import { Modal, Table, Typography, Tabs, DatePicker, Space, Select,  Row, Col  } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { PivotViewComponent } from '@syncfusion/ej2-react-pivotview';

import 'antd/dist/antd.css';
import FinancialStatistic from "../components/FinancialStatistic";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

const getDateFormat = (format) => {
  if(format === 'day') return 'DD/MM/YYYY';
  if(format === 'month') return 'MMM YYYY';
  if(format === 'quarter') return '[Q]Q YYYY';
  return 'YYYY';
}

const dataManipulation = (format, startingPeriod, endingPeriod) => {
  startingPeriod.startOf(format);
  endingPeriod = endingPeriod.endOf(format);
  return [startingPeriod, endingPeriod];
}

class Home extends Component {

  constructor(props) {
    super();
    this.state = {
      reportData: {},
      bankSummaryList: [],
      debtorList: [],
      watchlist: [],
      companyInformation: [],
      format: 'day',
      dateFormat: 'MMM YYYY',
      startPeriod: moment().subtract(1, 'month').startOf('month'),
      endPeriod: moment().add(1, 'month').endOf('month'),
      company: 'AA',
      modal: {
        visible: false,
        date: '',
        dateFormatted: '',
        ARInvoices: [],
        APInvoices: [],
        OSARInvoices: [],
        OSAPInvoices: [],
        APOverhead: [],
        APInvoicesPending: [],
        ProjectClaims: [],
        CostClaims: []
      },
      costClaimsSummary: {
        costData: {},
        revData: {},
      },
      generalFigures: {
        Expense: 0,
        COGS: 0,
        Income: 0,
        Revenue: 0,
        Percentages: {
          COGS: 0,
          Expense: 0,
          Income: 0
        }
      },
      previousGeneralFigures: {
        Expense: 0,
        COGS: 0,
        Income: 0,
        Revenue: 0,
        Percentages: {
          COGS: 0,
          Expense: 0,
          Income: 0
        }
      },
      SecuredRevenue: 0,
      CostsCommited: 0,
      dataSourceSettings: {
        url: `/api/pendingInvoices?company=AA`,
        expandAll: false,
        enableSorting: false,
        rows: [
            { name: 'Invoice_Date' },
            { name: 'Waiting_For'},
            { name: 'Vendor' }
        ],
        columns: [],
        values: [
            { name: 'Tax2', caption: 'Tax2' },
            { name: 'Subtotal', caption: 'Subtotal' }
        ],
        filters: [],
        formatSettings: [{name: 'Tax2', format: '$ ###.00' }, {name: 'Subtotal', format: '$ ###.00' }],
    }
    };
    this.getReportData = this.getReportData.bind(this);
    this.getDrilldownData = this.getDrilldownData.bind(this);
    this.changedFormat = this.changedFormat.bind(this);
    this.changedPeriod = this.changedPeriod.bind(this);
    this.changedCompany = this.changedCompany.bind(this);
    
  }

  /** Modal Functions */

  handleOk = e => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        visible: false
      }
    });
  };


  componentDidMount() {
    //Change default company

    let hasAllAccess = (this.props.user.vic >= 2 && this.props.user.qld >= 2);
    const getFirstCompany = () => {
      if(hasAllAccess) return 'AA'
      if(this.props.user.vic >= 2) return 'VI';
      if(this.props.user.qld >= 2) return 'QL';
    }

    const company = getFirstCompany();
    this.setState({
      ...this.state,
      company
    })
    this.getReportData(this.state.format, this.state.startPeriod, this.state.endPeriod, company);
    API.getTotalCostClaimsSummary('QL').then((costClaimsSummary) => {
      this.setState({costClaimsSummary: costClaimsSummary});

      console.log(costClaimsSummary);
    });
  }

  getReportData(format = "year", beginBack, endFront, company = this.state.company) {
    console.log(this.props.user);
    this.setState({
      ...this.state,
      format,
      dateFormat: getDateFormat(format),
      startPeriod: beginBack,
      endPeriod: endFront,
      company
    })
    API.getReportData(format, beginBack.format('YYYY-MM-DD'), endFront.format('YYYY-MM-DD'), company).then((res) => {
      this.setState({
        ...this.state,
        reportData: res.chartData,
        bankSummaryList: res.bankSummaryList,
        watchlist: res.watchlist,
        generalFigures: res.generalFigures,
        previousGeneralFigures: res.previousGeneralFigures,
        debtorList: res.debtorList,
        companyInformation: res.companyInformation,
        SecuredRevenue: res.SecuredRevenue,
        CostsCommited: res.CostsCommited,
      })
    });
    
  }

  getDrilldownData(date) {
    API.getDrilldownData(date, this.state.company).then((res) => {
      console.log(res);
      this.setState({
        ...this.state,
        modal: {
          ...this.state.modal,
          visible: true,
          date,
          dateFormatted: moment(date, 'DD/MM/YYYY').format('LL'),
          ARInvoices: res['ARInvoices'],
          APInvoices: res['APInvoices'],
          OSAPInvoices: res['OSAPInvoices'],
          OSARInvoices: res['OSARInvoices'],
          APOverhead: res['APOverhead'],
          APInvoicesPending: res['APInvoicesPending'],
          ProjectClaims: res['ProjectClaims'],
          CostClaims: res['CostClaims']
        }
      });
    })
  }

  changedFormat(value) {
    if(!this.state.startPeriod || !this.state.endPeriod) {
      console.log('SET');
      this.setState({
        ...this.state,
        startPeriod: moment('2017-01-01', 'YYYY-MM-DD'),
        endPeriod: moment('2021-12-31', 'YYYY-MM-DD')
      })
    }
    setTimeout(() => {
      //Recall API Data
      this.getReportData(value, this.state.startPeriod, this.state.endPeriod, this.state.company);
    }, 30)
  }

  changedPeriod(value, options) {
    if(value !== null) {
      console.log(value, options);
      
      //Data Manipulation
      let periodDates = dataManipulation(this.state.format, value[0], value[1]);
      let startingPeriod = periodDates[0]
      let endingPeriod = periodDates[1]

      this.setState({
        ...this.state,
        startPeriod: startingPeriod,
        endPeriod: endingPeriod
      })
      this.getReportData(this.state.format, startingPeriod, endingPeriod, this.state.company);
    } else {
      //Just set the value to null
      this.setState({
        ...this.state,
        startPeriod: null,
        endPeriod: null
      })
    }
    return value;
  }

  changedCompany(company) {
    this.setState({
      ...this.state,
      company,
      dataSourceSettings: {
        ...this.state.dataSourceSettings,
        url: `/api/pendingInvoices?company=${company}`
      }
    });
    this.pivotObj.dataSourceSettings.url = `/api/pendingInvoices?company=${company}`;
    this.pivotObj.refresh();
    this.getReportData(this.state.format, this.state.startPeriod, this.state.endPeriod, company);
  }

  render () {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const classificationTypes = ['BUILDERS LICENCE', 'ACCREDITATION', 'MOTOR VEHICLE REGISTRATION', 'BANK GUARANTEE', 'STATUTORY OBLIGATION', 'INSURANCE', 'WORKERS COMP INSURANCE', 'BAS PAYG FBT', 'OTHER'];
    return <div className="home">
      <Modal
        title={
        <Typography>
          <Title level={4}>{this.state.modal.dateFormatted}</Title>
          <Tabs defaultActiveKey="2">
            <TabPane tab="AR Invoices" key="2">
              <Table dataSource={this.state.modal.ARInvoices} columns={[{
                title: 'Job Number',
                dataIndex: 'Job_Number',
              },
              {
                title: 'Job Name',
                dataIndex: 'Job_Name',
              },
              {
                title: 'Invoice Number',
                dataIndex: 'Invoice_Number',
              }, {
                title: 'Total',
                dataIndex: 'Total',
                render: (text) => formatter.format(text)
              }, {
                title: 'Company',
                dataIndex: 'Company',
              }]} />;
            </TabPane>
            <TabPane tab="AP Invoices" key="3">
              <Table dataSource={this.state.modal.APInvoices} columns={[{
                title: 'Vendor',
                dataIndex: 'VendorName',
              },
              {
                title: 'Invoice Number',
                dataIndex: 'Invoice_Number',
              }, {
                title: 'Total',
                dataIndex: 'PaidAmount',
                render: (text) => formatter.format(text)
              }, {
                title: 'Company',
                dataIndex: 'Company',
              }]} />
            </TabPane>
            <TabPane tab="O/S AR Invoices" key="4">
              <Table dataSource={this.state.modal.OSARInvoices} columns={[{
                title: 'Job Number',
                dataIndex: 'Job_Number',
              },
              {
                title: 'Job Name',
                dataIndex: 'Job_Name',
              },
              {
                title: 'Invoice Number',
                dataIndex: 'Invoice_Number',
              }, {
                title: 'Total',
                dataIndex: 'Total',
                render: (text) => formatter.format(text)
              }, {
                title: 'Amount Paid',
                dataIndex: 'Amount_Paid',
                render: (text) => formatter.format(text)
              }, {
                title: 'Company',
                dataIndex: 'Company',
              }]} />
            </TabPane>
            <TabPane tab="O/S AP Invoices" key="5">
              <Table dataSource={this.state.modal.OSAPInvoices} columns={[{
                title: 'Job Number',
                dataIndex: 'Job_Number',
              },
              {
                title: 'Invoice Number',
                dataIndex: 'Invoice_Number',
              }, {
                title: 'Total',
                dataIndex: 'Invoice_Total',
                render: (text) => formatter.format(text)
              }, {
                title: 'Company',
                dataIndex: 'Company',
              }]} />
            </TabPane>
            <TabPane tab="AP Invoices Pending" key="6">
              <Table dataSource={this.state.modal.APInvoicesPending} columns={[{
                title: 'Job Number',
                dataIndex: 'Job_Number',
              },
              {
                title: 'Invoice Number',
                dataIndex: 'Invoice_Number',
              }, {
                title: 'Total',
                dataIndex: 'Invoice_Total',
                render: (text) => formatter.format(text)
              }, {
                title: 'Company',
                dataIndex: 'Company',
              }]} />
            </TabPane>
            <TabPane tab="AP Overhead" key="7">
              <Table dataSource={this.state.modal.APOverhead} columns={[{
                title: 'Type',
                dataIndex: 'type',
              },
              {
                title: 'Amount',
                dataIndex: 'amount',
                render: (text) => formatter.format(text)
              }]} />
            </TabPane>
            <TabPane tab="Cost Claims" key="8">
              <Table dataSource={this.state.modal.CostClaims} columns={[
              {
                title: 'Company',
                dataIndex: 'company',
              },
              {
                title: 'Job Number',
                dataIndex: 'jobNumber',
              },
              {
                title: 'Item',
                dataIndex: 'item',
              },
              {
                title: 'Amount', 
                dataIndex: 'actual_cost',
                render: (text) => formatter.format(text)
              }
              ]} />
            </TabPane>
            <TabPane tab="Project Claims" key="9">
              <Table dataSource={this.state.modal.ProjectClaims} columns={[
              {
                title: 'Company',
                dataIndex: 'company',
              },
              {
                title: 'Job Number',
                dataIndex: 'jobNumber',
              },
              {
                title: 'Item',
                dataIndex: 'item',
              },
              {
                title: 'Amount', 
                dataIndex: 'actual_cost',
                render: (text) => formatter.format(text)
              }
              ]} />
            </TabPane>
          </Tabs>
        </Typography>
        }
        visible={this.state.modal.visible}
        width={'75%'}
        onOk={this.handleOk}
        onCancel={this.handleOk}
      >
      </Modal>
      <div className="chartOptions">
        <Select style={{ width: '100%' }} value={this.state.company} onChange={this.changedCompany}>
          {(this.props.user.vic >= 2 && this.props.user.qld >= 2) && <Option value="AA">All Companies</Option>}
          {(this.props.user.vic >= 2) && <Option value="VI">Victoria</Option>}
          {(this.props.user.qld >= 2) && <Option value="QL">Queensland</Option>}
        </Select>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="chartContainer">
            <h2>Company Cashflow</h2>
            <Bar
              data={this.state.reportData}
              width={1000}
              height={300}
              options={{
                events: ['mousemove', 'click'],
                tooltips: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                      label: (tooltipItem, data) => {
                        return `${this.state.reportData.datasets[tooltipItem.datasetIndex].label} ${parseInt(tooltipItem.yLabel) >= 0 ? '' : '-'}$${Math.abs(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                      }
                    }
                },
                responsive: true,
                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            callback: function (value) {
                                return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            },
                            beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Amount $'
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        scaleLabel: {
                          display: true,
                          labelString: 'Time' 
                        },
                        ticks: {
                          beginAtZero: true
                        }
                    }],
                },
                'onClick': (evt, item) => {
                  if(item.length !== 0) {
                    const getNextPeriod = () => {
                      let nextPeriod = ''
                      switch (this.state.format){
                        case 'year':
                          nextPeriod = 'quarter';
                          break
                        case 'quarter':
                          nextPeriod = 'month';
                          break;
                        case 'month':
                          nextPeriod = 'day';
                          break;
                        default:
                          break
                      }
                      return nextPeriod;
                    }
                    if(this.state.format !== 'day') {
                      //Period is not a day, so can go smaller.
                      //We need to calculate the period here.
                      console.log(evt, item);
                      let index = item[0]._index;
                      let label = this.state.reportData.labels[index];
                      let periodDates = dataManipulation(this.state.format, moment(label, this.state.dateFormat), moment(label, this.state.dateFormat));
                      let startingPeriod = periodDates[0];
                      let endingPeriod = periodDates[1];
                      this.getReportData(getNextPeriod(), startingPeriod, endingPeriod, this.state.company);
                    } else {
                      //Is a day, so we need to load and display the drilldown modal.
                      let index = item[0]._index;
                      let label = this.state.reportData.labels[index];
                      console.log('Daily Drilldown', label);
                      this.getDrilldownData(label);
                    }
                  }
                },
                'onHover': (evt, item) => {
                  document.getElementsByTagName('canvas')[0].style.cursor = item.length !== 0 ? 'pointer' : 'default'
                }
            }}
            />
            <Space direction="horizontal" size={12}>
              {/* <Space direction="vertical">
                <h4>Format</h4>
                <Select style={{ width: '100%' }} value={this.state.format} onChange={this.changedFormat}>
                  <Option value="year">Yearly</Option>
                  <Option value="quarter">Quarterly</Option>
                  <Option value="month">Monthly</Option>
                  <Option value="day">Daily</Option>
                </Select>
              </Space> */}
              <Space direction="vertical">
                <h4>Period</h4>
                <RangePicker picker={this.state.format} onChange={this.changedPeriod} format={this.state.dateFormat} value={[this.state.startPeriod,this.state.endPeriod]}/>
              </Space>
            </Space>
        </div>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} style={{marginTop: '16px'}}>
      <Col span={12}>
            <div className="accountSummary">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                <h2>Bank Summary</h2>
                  <Table dataSource={this.state.bankSummaryList} pagination={false} columns={[{title: 'Account', dataIndex: 'account', key: 'account'}, {title: '$ Amount', dataIndex: 'amount', key: 'amount'}]} />
                </Col>
                <Col span={12}>
                <h2>Account Watchlist</h2>
                  <Table scroll={{y: 'max-content'}} dataSource={this.state.watchlist} pagination={false} columns={[{title: 'Account', dataIndex: 'account', key: 'account'}, {title: '$ Amount', dataIndex: 'amount', key: 'amount'}]} />
                </Col>
              </Row>
            </div>
        </Col>
        <Col span={12}>
          <div className="debtorList">
            <h2>Debtor List</h2>
            <Table
            dataSource={this.state.debtorList}
            columns={[
              {title: 'Client', dataIndex: 'Billed_To', key: 'Billed_To'},
              {title: 'Job Number', dataIndex: 'Job_Number', key: 'Job_Number'},
              {title: 'Invoice Number', dataIndex: 'Invoice_Number', key: 'Invoice_Number'},
              {title: 'Invoice Date', dataIndex: 'Invoice_Date', key: 'Invoice_Date'},
              {title: 'Due Date', dataIndex: 'Due_Date', key: 'Due_Date'},
              {title: 'Total Incl GST', dataIndex: 'Total', key: 'Total'},
              {title: 'Amount Owing Incl GST', dataIndex: 'Amount_Owing', key: 'Amount_Owing'}]} />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
      <Col span={12}>
        <div className="chartContainer">
          <h2>AP Pending Invoices</h2>
          <br/>
          <PivotViewComponent ref={d => this.pivotObj = d} id='PivotView' height={600} dataSourceSettings={this.state.dataSourceSettings}></PivotViewComponent>
        </div>
      </Col>
      <Col span={12}>
        <div className="chartContainer">
          <h2>Company Information</h2>
          <br/>
          <Table 
            scroll={{y: 'max-content'}}
            dataSource={this.state.companyInformation}
            pagination={false}
            columns={[
              {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                filters: _.uniq(this.state.companyInformation.map(record => record.description)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.description && record.description.indexOf(value) !== -1;
                }
              },
              {
                title: 'Classification',
                dataIndex: 'classification',
                key: 'classification',
                filters: classificationTypes.map(text => { return {text, value: text} }),
                onFilter: (value, record) => {
                  if(value !== 'OTHER') return record.classification.includes(value);
                  return !classificationTypes.includes(record.classification) || record.classification.includes(value);
                }},
              {
                title: 'Licence #',
                dataIndex: 'licence_number',
                key: 'licence_number',
                filters: _.uniq(this.state.companyInformation.map(record => record.licence_number)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.licence_number && record.licence_number.indexOf(value) !== -1;
                }
              },
              {
                title: 'Due Date',
                dataIndex: 'due_date_formatted',
                key: 'due_date',
                filters: _.uniq(this.state.companyInformation.map(record => record.due_date_formatted)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.due_date_formatted && record.due_date_formatted.indexOf(value) !== -1;
                }
              }]}
              rowClassName={(record, index) => {
              if(record.completed) return "companyInformationCompleted";
              if(record.dayDiff <= 0) return "companyInformationDanger";
              if(record.dayDiff > 0 && record.dayDiff <= 14) return "companyInformationWarning";
              return "";
            }} />
        </div>
      </Col>
      </Row>
    </div>
  }

}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUser: API.getUser
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
