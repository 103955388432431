import React, { useContext, useState, useEffect, useRef, Component }  from "react";
import 'antd/dist/antd.css';
import './CompanyInformation.scss';
import 'antd/dist/antd.css';
import { DatePicker, Select, Space, Table, Input, Button, Popconfirm, Form, InputNumber } from 'antd';
import moment from 'moment';
import debounce from 'lodash.debounce';
import _ from 'lodash';
import * as API from '../api/api';
const { Option } = Select;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class CompanyInformation extends Component {

  constructor(props) {
    super();

    this.state = {
      dataSource: [],
      classificationTypes: [],
      count: 0
    };

    this.classificationTypes = ['BUILDERS LICENCE', 'ACCREDITATION', 'MOTOR VEHICLE REGISTRATION', 'BANK GUARANTEE', 'STATUTORY OBLIGATION', 'INSURANCE', 'WORKERS COMP INSURANCE', 'BAS PAYG FBT', 'OTHER'];
  
    this.addRow = this.addRow.bind(this);
    this.loadInformation = this.loadInformation.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateDescription =  debounce(this.handleUpdate, 1000);
    this.handleUpdateClassification =  debounce(this.handleUpdate, 1000);
    this.handleUpdateLicence =  debounce(this.handleUpdate, 1000);
    this.handleUpdateCompleted = debounce(this.handleUpdate, 1000);
  }

  componentDidMount() {
    this.loadInformation(false);
  }

  loadInformation(orderByCreated = true) {
    API.getCompanyInformation(orderByCreated).then(async (res) => {
      this.setState({
        dataSource: res.rows,
        count: res?.rows ? res.rows.length : 0
      });
    });
  }

  deleteRow = (id) => {
    API.deleteCompanyInformationRecord(id).then(res => {
      this.loadInformation();
    })
  }

  handleUpdate(uuid, record) {
    API.updateCompanyInformation(uuid, record).then((res) => {
      this.loadInformation();
    })
  }

  addRow = () => {
    API.addCompanyInformation(new Date()).then((res) => {
      //Reload data
      this.loadInformation();
    });
  }

  render () {
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    return <div className="CompanyInformation">
      <div className="information">
        <div>
          <Button onClick={this.addRow} type="primary" style={{ marginBottom: 16 }}>
            Add Record
          </Button>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            dataSource={this.state.dataSource}
            columns={[
              {
                title: 'Description',
                dataIndex: 'description',
                width: '30%',
                editable: true,
                render: (_, record) => 
                  this.state.dataSource.length >= 1 ? (
                    <Input
                      onChange={(str) => {
                        this.handleUpdateDescription(record.id, {...record, description: str.target.value});
                      }}
                      defaultValue={record.description}
                      />
                  ) : null,
                filters: _.uniq(this.state.dataSource.map(record => record.description)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.description && record.description.indexOf(value) !== -1;
                }
              },
              {
                title: 'Classification',
                dataIndex: 'classification',
                width: '25%',
                editable: true,
                filters: this.classificationTypes.map(text => { return {text, value: text} }),
                onFilter: (value, record) => {
                  if(value !== 'OTHER') return record?.classification && record.classification.includes(value);
                  return !this.classificationTypes.includes(record?.classification) || record?.classification && record.classification.includes(value);
                },
                render: (_, record) => 
                  this.state.dataSource.length >= 1 ? (
                    <Select
                      defaultValue={record.classification}
                      onChange={(value) => {
                        this.handleUpdateClassification(record.id, {...record, classification: value});
                      }}
                      style={{ width: '300px' }}>
                      {this.classificationTypes.map(type => <Option value={type}>{type}</Option>)}
                    </Select>
                  ) : null,
              },
              {
                title: 'Licence Number',
                dataIndex: 'licence_number',
                editable: true,
                render: (_, record) => 
                  this.state.dataSource.length >= 1 ? (
                    <Input
                      onChange={(str) => {
                        this.handleUpdateLicence(record.id, {...record, licence_number: str.target.value});
                      }}
                      defaultValue={record.licence_number}
                      />
                  ) : null,
                filters: _.uniq(this.state.dataSource.map(record => record.licence_number)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.licence_number && record.licence_number.indexOf(value) !== -1;
                }
              },
              {
                title: 'Due Date',
                dataIndex: 'dueDate',
                editable: true,
                render: (_, record) =>
                  this.state.dataSource.length >= 1 ? (
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={(dt, dtStr) => {
                        if(dtStr !== '') this.handleUpdate(record.id, {...record, dueDate: dtStr});
                      }}
                      defaultValue={moment(record.dueDate, moment.ISO_8601)}
                      />
                  ) : null,
                filters: _.uniq(this.state.dataSource.map(record => record.dueDate)).map(text => ({ text, value: text })),
                onFilter: (value, record) => {
                  return record?.dueDate && record.dueDate.indexOf(value) !== -1;
                }
              },
              {
                title: 'Completed?',
                dataIndex: 'action',
                render: (_, record) =>
                  this.state.dataSource.length >= 1 ? (
                    <Select style={{ width: '100%' }} defaultValue={record.completed} onChange={(v) => { this.handleUpdateCompleted(record.id, {...record, completed: v}); }}>
                        {<Option value={true}>Yes</Option>}
                        {<Option value={false}>No</Option>}
                    </Select>
                  ) : null,
                filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
                onFilter: (value, record) => {
                  return record?.completed !== undefined && record?.completed == value;
                }
              },
              {
                title: 'Delete',
                dataIndex: 'delete',
                render: (_, record) => <Button onClick={() => this.deleteRow(record.id)}>Delete</Button>
              }
            ]}
          />
        </div>
      </div>
    </div>
  }

}
 

export default CompanyInformation;
