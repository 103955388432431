import React from 'react';
import './assets/fonts.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom";

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Landing from './pages/Landing';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as API from './api/api';
import ARAPDataEntry from './pages/ARAPDataEntry';
import ProjectDataEntry from './pages/ProjectDataEntry';
import ChartOfAccounts from './pages/ChartOfAccounts';
import Users from './pages/Users';
import CompanyInformation from './pages/CompanyInformation';
import JobSummary from './pages/JobSummary';
import { Component } from 'react';
import JobCostEstimate from './pages/JobCostEstimate';
import JobCostSummary from './pages/JobCostSummary';
import ManageUserAccess from './pages/UserAccess';
import TenderStartUp from './pages/TenderStartUp';
import FiscalSummary from './pages/CompanyInfo/FiscalSummary';
import GoNoGo from './pages/GoNoGo';

const developmentPrefix = '';

class App extends Component {

  componentDidMount() {
      const { getUser } = this.props;
      getUser();
  }

  userAuthenticated (children) {
    if(this.props.user.isFetching) return <React.Fragment />
  
    // User has been fetched

    if(this.props.user.isUser) return children;

    return <Redirect to="/" />
  }

  render () {
    

    return (
      <Router>
        <Switch>
          <Route path="/dashboard">
            {this.userAuthenticated(<React.Fragment>
              {(this.props.user.qld === 1 && this.props.user.vic === 1) ? <Redirect to="/jobs" /> : <Navbar title="Home" user={this.props.user}> <Home /> </Navbar>}
            </React.Fragment>)}
          </Route>

          <Route path="/finance/summary">
            {this.userAuthenticated(<Navbar title="Financial Summary" user={this.props.user}>
              <FiscalSummary />
            </Navbar>)}
          </Route>
          
            <Route path="/job_cost_estimate">
              {this.userAuthenticated(<Navbar title="Job Cost Estimate" user={this.props.user}>
                <JobCostEstimate />
              </Navbar>)}
            </Route>
    
            <Route path="/job_cost_start_up">
              {this.userAuthenticated(<Navbar title="Job Cost Start Up" user={this.props.user}>
                <TenderStartUp />  
              </Navbar>)}
            </Route>
            <Route path="/go-no-go">
              {this.userAuthenticated(<Navbar title="Tender Go-No-Go" user={this.props.user}>
                <GoNoGo />
              </Navbar>)}
            </Route>
          
          <Route path="/arap_entry">
            {this.userAuthenticated(<Navbar title="AR/AP Data Entry" user={this.props.user}>
              <ARAPDataEntry />
            </Navbar>)}
          </Route>
          <Route path="/project_entry">
            {this.userAuthenticated(<Navbar title="Project Data Entry" user={this.props.user}>
              <ProjectDataEntry />
            </Navbar>)}
          </Route>
          <Route path="/coa">
            {this.userAuthenticated(<Navbar title="Chart of Accounts" user={this.props.user}>
              <ChartOfAccounts />
            </Navbar>)}
          </Route>
          <Route path="/users">
            {this.userAuthenticated(<Navbar title="Manage Users" user={this.props.user}>
              <Users />
            </Navbar>)}
          </Route>
          <Route path="/access">
            {this.userAuthenticated(<Navbar title="Manage User Access" user={this.props.user}>
              <ManageUserAccess />
            </Navbar>)}
          </Route>
          <Route path="/company_information">
            {this.userAuthenticated(<Navbar title="Company Information" user={this.props.user}>
              <CompanyInformation />
            </Navbar>)}
          </Route>
          <Route path="/jobs">
            {this.userAuthenticated(<Navbar title="Job Summary" user={this.props.user}>
              <JobSummary />
            </Navbar>)}
          </Route>
          <Route path="/job_cost_summary">
            {this.userAuthenticated(<Navbar title="Job Cost Summary" user={this.props.user}>
              <JobCostSummary />
            </Navbar>)}
          </Route>
          <Route exact path="/">
              <Landing/>
          </Route>
          <Route path="/login" render={() => {window.location.href = `${developmentPrefix}/auth/microsoft`; return null}}/>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUser: API.getUser
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
