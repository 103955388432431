import {React, useState, useEffect} from "react";
import * as API from '../api/api';
import './CriticalDates.scss';
import { Table, Input, DatePicker, Select, Button, Popconfirm } from "antd";
import moment from "moment";

const { Option } = Select;

// constant formats:
const dateFormat = 'YYYY-MM-DD';
const ConfText = "Are you sure you want to delete this project?";

function CriticalDates(props) {
    // State Variables:
    const [data, setData] = useState([]);

    useEffect(() => {
        API.getJobCritDates(props.company, props.job).then(res => {
            setData(res.resData);
        })
    }, [props.company, props.job]);

    const addJob = () => {
        API.addJobCritDates(props.company, props.job).then(updateRenderedData());
    }

    const updateRenderedData = () => {
        API.getJobCritDates(props.company, props.job).then(res => {
            console.log("Updated!");
            setData(res.resData);
        })
    }

    // Critical Date Cols:
    const columns = [
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc',
            render: (_, record) => (
                <Input
                    key={record.id + "desc"}
                    defaultValue={record.desc}
                    placeholder='Input Description'
                    onChange={(inputStr) => {
                        record.desc = inputStr.target.value;
                        API.updateJobCritDates(props.company, props.job, record.id, record);
                    }}
                />
            )
        },
        {
            title: 'Due Date',
            dataIndex:'dueDate',
            key: 'dueDate',
            render: (_, record) => (
                <DatePicker
                    key={record.id + "dueDate"}
                    defaultValue={() => {
                        if (record.dueDate === null) {
                            return null;
                        } else {
                            return moment(record.dueDate)
                        }
                    }}
                    placeholder={"Due Date"}
                    format={dateFormat}
                    onChange={(date, dateStr) => {
                        record.dueDate = dateStr;
                        API.updateJobCritDates(props.company, props.job, record.id, record).then(updateRenderedData);
                        }
                    }
                    allowClear={false}
                />
            )
        },
        {
            title: 'Completed',
            dataIndex: 'complete',
            key: 'complete',
            render: (_, record) => (
                <Select 
                    key={data.id + "complete"} 
                    defaultValue={record.completed}
                    style={{minWidth: 100}}
                    onChange={(value) => {
                        record.completed = value;
                        API.updateJobCritDates(props.company, props.job, record.id, record).then(updateRenderedData);
                    }}    
                >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                </Select>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Popconfirm
                    title={ConfText}
                    onConfirm={() => {
                        API.deleteJobCritDates(props.company, props.job, record.id).then(updateRenderedData());
                    }}
                    okText="Yes"
                    cancelText="Cancel"    
                >
                    <Button>Delete</Button>
                </Popconfirm>
            )
        }
    ]

    return(
        <div className="card-full">
            <h3 className="title">Critical Dates</h3>
            <Button className="button" style={ {marginLeft: '10px'}} onClick={addJob}>Add Job</Button>
            <Table 
                className="table"
                columns={columns} 
                dataSource={data} 
                key={record => record.key}
                pagination={false}
                bordered 
            />
        </div>
    )
}

export default CriticalDates;