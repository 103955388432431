import { Button, Checkbox, Modal, Radio, Select, Space } from "antd";
import React, { useLayoutEffect, useState } from "react";
import * as API from "../../api/api";
import './UserAccessPanel.scss';

export default function UserAccessPanel(props) {
    const [roles, setRoles] = useState([props.roles]);
    const [user, setUser] = useState([]);
    const [hasTender, setHasTender] = useState() 
    const [hasJob, setHasJob] = useState()
    const [hasInfo, setHasInfo] = useState()
    const [tenderAccess, setTenderAccess] = useState()
    const [jobAccess, setJobAccess] = useState()
    const [infoAccess, setInfoAccess] = useState()
    const [isVisible, setIsVisible] = useState(false)

    useLayoutEffect(() => {
        API.getUserByEmail(props.selectedUser).then((res) => { 
            setUser(res);
        })
        setRoles(props.roles);

        API.getUserAccessByEmail(props.selectedUser).then((res) => { 
            console.log(res);
            // Set allowed to true if the user has access to the resource.
            setHasTender(res.tenderAllowed);
            setHasJob(res.jobAllowed);
            setHasInfo(res.infoAllowed);

            // Set the access level for the resource.
            setTenderAccess(res.tenderAccess);
            setJobAccess(res.jobAccess);
            setInfoAccess(res.infoAccess);
        })

    }, [props.selectedUser]);

    const updateUserAccess = () => {
        const access = {
            tenderAllowed: hasTender,
            jobAllowed: hasJob,
            infoAllowed: hasInfo,
            tenderAccess: tenderAccess,
            jobAccess: jobAccess,
            infoAccess: infoAccess
        }
        API.updateUserAccessByEmail(props.selectedUser, access).then((res) => {
            console.log(res);
        })
        setIsVisible(true);
    }

    return (
        <>
            <div className="user-label-container">
                <span>Selected user: </span>
                <span className="user-label">{props.selectedUser}</span>
                <span>User Type (QLD): </span>
                <span className="user-label">
                    {roles.map((role) => {
                    if(role.id == user.QLD) {
                        return role.display
                    }
                    })}
                </span>
                <span>User Type (VIC): </span>
                <span className="user-label">
                    {roles.map((role) => {
                    if(role.id == user.VIC) {
                        return role.display
                    }
                    })}
                </span>
            </div>
            <div className="check-container">
                <Checkbox 
                    onChange={(event) => {
                        setHasInfo(event.target.checked);
                    }} checked={hasInfo}>Has Access to Company Information Material</Checkbox>
                <Radio.Group 
                    disabled={!hasInfo} 
                    value={infoAccess} 
                    onChange={(event) => {
                        setInfoAccess(event.target.value);
                    }} 
                    className="radio-group"
                >
                    <Space direction="vertical">
                        <Radio value={0}>Read Access</Radio>
                        <Radio value={1}>Write Access</Radio>
                        <Radio value={2}>Read/Write Access</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className="check-container">
                <Checkbox onChange={(event) => {
                    setHasTender(event.target.checked);
                }}  checked={hasTender}>Has Access to Tender Material</Checkbox>
                <Radio.Group 
                    disabled={!hasTender} 
                    value={tenderAccess} 
                    onChange={(event) => {
                        setTenderAccess(event.target.value);
                    }} 
                    className="radio-group"
                >
                    <Space direction="vertical">
                        <Radio value={0}>Read Access</Radio>
                        <Radio value={1}>Write Access</Radio>
                        <Radio value={2}>Read/Write Access</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className="check-container">
                <Checkbox onChange={(event) => {
                    setHasJob(event.target.checked);
                }}  checked={hasJob}>Has Access to Job Material</Checkbox>
                <Radio.Group 
                    disabled={!hasJob} 
                    value={jobAccess} 
                    onChange={(event) => {
                        setJobAccess(event.target.value);
                    }} 
                    className="radio-group"
                >
                    <Space direction="vertical">
                        <Radio value={0}>Read Access</Radio>
                        <Radio value={1}>Write Access</Radio>
                        <Radio value={2}>Read/Write Access</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className="button-container">
                <Button onClick={updateUserAccess}>Save Changes</Button>
                <Modal title="Success" visible={isVisible} onOk={() => {setIsVisible(false)}} onCancel={() => {setIsVisible(false)}}>
                    <p>User access has been updated.</p>
                </Modal>
                <span className="warning-text">Please note that updates to a user's access level are applied after the account logs out!</span>
            </div>
            
        </>
    )
}