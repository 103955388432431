import { FETCH_USER_PENDING, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from './user.types';

export const fetchUserPending = () => {
    return {
        type: FETCH_USER_PENDING
    };
};

export const fetchUserSuccess = (user) => {
    return {
        type: FETCH_USER_SUCCESS,
        user
    };
};

export const fetchUserError = (error) => {
    return {
        type: FETCH_USER_ERROR,
        error
    }
}

