import { FETCH_USER_PENDING, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from './user.types';

const INITIAL_STATE = {
    isFetching: true,
    hasError: false,
    qld: -1,
    vic: -1,
    tenderAccess: -1,
    jobAccess: -1,
    infoAccess: -1,
    apiKeys: {
        isFetching: false,
        hasError: false,
        list: []
    }
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_USER_PENDING:
            return {
                ...state,
                isFetching: true,
                hasError: false,
                isUser: false,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasError: false,
                isUser: true,
                ...action.user
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                hasError: true,
                isUser: false,
                error: action.error
            }
        default: return state;
    }
};

export default reducer;
