import { Component } from "react";
import React from 'react';
import './FinancialStatistic.scss';

class FinancialStatistic extends Component {
    render () {
        return <div className={`FinancialStatistic ${this.props.colour === undefined ? 'Blue' : this.props.colour}Card`}>
            <h2>{this.props.title}<span className="financialYear">{this.props.financialYear}</span></h2>
            <p><span>$</span>{this.props.value}{this.props.margin && <span className="margin">{(this.props.margin || 0).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})}</span>}</p>
        </div>
    }
}

export default FinancialStatistic;
