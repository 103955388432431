import React, { Component }  from "react";
import 'antd/dist/antd.css';
import './JobSummary.scss';
import * as API from '../api/api';
import { Bar } from 'react-chartjs-2';
import { Select, Space, Table, InputNumber, notification, Tabs, Row, DatePicker, Col } from 'antd';
import { connect } from "react-redux";
import moment from "moment";
import RiskOpportunity from "../components/RiskOpportunity";
import JobRiskOpportunitySummary from '../components/RiskOpportunitySummary';
import CriticalDates from '../components/CriticalDates';
import CriticalDatesSummary from "../components/CritDateSummary";

const { Option } = Select;
const { TabPane } = Tabs;



const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

class JobSummary extends Component {

  constructor(props) {
    super();

    this.state = {
        company: 'QL',
        job: '',
        jobs: [],
        isLoaded: false,
        data: {},
        costItemData: {
          costItems: []
        },
        progressClaimData: {
          progressClaims: []
        },
        costColumns: [],
        claimColumns: [],
        summaryData: {
          datasets: [],
          labels: []
        },
        forecastData: {
          datasets: [],
          labels: []
        },
        percentDiffObj: {
          headContract: {}, 
          budget: {},
          profitMarginsValue: {},
          profitMarginsPercent: {},
          cashPosition: {},
          incomeInAdvance: {},
          monthYear: {},
        },
        costClaimsSummary: {
          columns: [],
          data: {},
          financialYears: []
        }

    };

    this.loadJobs = this.loadJobs.bind(this);
    this.changedCompany = this.changedCompany.bind(this);
    this.changedJob = this.changedJob.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.compareJobData = this.compareJobData.bind(this);
    this.getJobMonthYear = this.getJobMonthYear.bind(this);
    
  }

  componentDidMount() {
    let hasAllAccess = (this.props.user.vic >= 2 && this.props.user.qld >= 2);
    const getFirstCompany = () => {
      if(hasAllAccess) return 'QL'
      if(this.props.user.qld >= 1) return 'QL';
      if(this.props.user.vic >= 1) return 'VI';
    }

    const company = getFirstCompany();
    this.setState({
      ...this.state,
      company
    })
    this.loadJobs(company);
  }

  /**
   * Loads the jobs for the selected company.
   * @param {string} company 
   */
  loadJobs(company) {
    API.getJobs(company || this.state.company).then((res) => {
      this.setState({
        ...this.state,
        jobs: res
      })
    });
  }

  /**
   * Triggered when the company drowpdown is changed.
   * @param {string} company 
   */
  changedCompany(company) {
    this.setState({
      ...this.state,
      company
    })
    this.loadJobs(company);
  }

  /**
   * Updates a cost item percentage. 
   */
  onChangedCostPer(changedValue, record, index) {
    // Get the period id from the change index.
    const periodId = this.state.costItemData.formattedPredictedPeriods[index].id;
    API.updateJobCostItemClaimPer(this.state.company, this.state.job, periodId, record.costItemName, record.costTypeCode, changedValue).then((res) => {
      if(res.err) {
        const error = res.response.data.error;
        notification['error']({
          message: 'Claim above 100%',
          description: error,
        });

        // Reset the value to the previous value.
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [`costClaimPer${index}`]: record[`costClaimPer${index}`]
          }
        })
      } else {
        this.changedJob(this.state.job);
      }
    });
  }

  /**
   * Updates a progress claim percentage.
   */
  onChangesProgressPer(changedValue, record, index) {
    const periodId = this.state.progressClaimData.formattedPredictedPeriods[index].id;
    API.upgradeProgressClaimClaimPer(this.state.company, this.state.job, periodId, record.itemNumber, changedValue).then((res) => {
      if(res.err) {
        const error = res.response.data.error;
        notification['error']({
          message: 'Claim above 100%',
          description: error,
        });

        // Reset the value to the previous value.
      } else {
        this.changedJob(this.state.job);
      }
    });
  }

  /**
   * Loads the data for a job, given a job number.
   * @param {string} job 
   */
  changedJob(job) {
    var now = new Date;
    API.getJobData(this.state.company, job, now.getFullYear(), now.getMonth() + 1).then((jobData) => {
      API.getJobCostItemData(this.state.company, job).then((costItemData) => {
        API.getProgressClaimsData(this.state.company, job).then((progressClaimData) => {
          API.getJobSummaryData(this.state.company, job).then((summaryData) => {
            API.getJobForecastData(this.state.company, job).then((forecastData) => {
              API.getCostClaimsSummary(this.state.company, job).then((costClaimsSummary) => {
                // Add columns
                const costColumns = this.getDefaultCostColumns();
                const claimColumns = this.getDefaultClaimPositionColumns();

                // For each formattedPredictedPeriods, add a column
                for(let i = 0; i < costItemData.formattedPredictedPeriods.length; i++) {
                  const period = costItemData.formattedPredictedPeriods[i];
                  const costColumnItem =  {
                    title: period.display,
                    children: [
                      {
                        title: '%',
                        dataIndex: `costClaimPer${i}`,
                        key: `costClaimPer${i}-${job}`,
                        render: (text, record) => (
                          <InputNumber
                            value={parseFloat(text)}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            onChange={(changedValue) => this.onChangedCostPer(changedValue, record, i)}
                          />
                        )
                      },
                      {
                        title: '$',
                        dataIndex: `costClaimAct${i}`,
                        key: `costClaimAct${i}-${job}`,
                        render: (text) => formatter.format(text || '0')
                      }
                    ]
                  }

                  // Progress Claims
                  const progressColumnItem =  {
                    title: period.display,
                    children: [
                      {
                        title: '%',
                        dataIndex: `progressClaimPer${i}`,
                        key: `progressClaimPer${i}-${job}`,
                        render: (text, record) => (
                          <InputNumber
                            value={parseFloat(text)}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            onChange={(changedValue) => this.onChangesProgressPer(changedValue, record, i)}
                          />
                        )
                      },
                      {
                        title: '$',
                        dataIndex: `progressClaimAct${i}`,
                        key: `progressClaimAct${i}-${job}`,
                        render: (text) => formatter.format(text || '0')
                      }
                    ]
                  }

                  costColumns.push(costColumnItem);
                  claimColumns.push(progressColumnItem);
                }
                
                this.setState({
                  isLoaded: true,
                  data: jobData,
                  costItemData,
                  progressClaimData,
                  costColumns,
                  claimColumns,
                  job,
                  summaryData,
                  forecastData,
                  costClaimsSummary,
                });

                this.compareJobData(now.getFullYear(), now.getMonth() + 1);
              });
            });
          });
        });
      });
    })
  }

 //  ------- Table Columns -------

  getDefaultCostColumns = () => {
    return [{
      title: 'Cost Item',
      dataIndex: 'costItemName',
      key: 'costItemName',
    },
    {
      title: 'Cost Type',
      dataIndex: 'costTypeCode',
      key: 'costTypeCode'
    },
    {
      title: 'Actual Cost',
      dataIndex: 'totalActual',
      key: 'totalActual',
      render: (text) => formatter.format(text)
    },
    {
      title: 'Estimate At Completion',
      dataIndex: '_eacCost',
      key: '_eacCost',
      render: (text) => formatter.format(text)
    },
    {
      title: 'Estimate To Completion',
      dataIndex: '_etcCost',
      key: '_etcCost',
      render: (text) => formatter.format(text)
    }];
  }

  getDefaultClaimPositionColumns = () => {
    return [{
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber"
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Scheduled Value",
      dataIndex: "scheduledValue",
      key: "scheduledValue",
      render: (text) => formatter.format(text)
    },
    {
      title: "Works Completed",
      dataIndex: "totalcompleted",
      key: "totalcompleted",
      render: (text) => formatter.format(text)
    },
    {
      title: "Remaining Claim",
      dataIndex: "remainingClaim",
      key: "remainingClaim",
      render: (text) => formatter.format(text)
    }];
  }

  // getJobMonthYear:
  getJobMonthYear(job) {
    API.getJobMonthYear(this.state.company).then(jobMothYearData => {
      console.log(jobMothYearData)
    })
  }

  // dateChanged: retrieves the selected month and year and passes the value to the getJobData for re-render.
  dateChanged(date, dateString, job) {
    let year = parseInt(dateString.slice(0, 4))
    let month = parseInt(dateString.slice(5))

    API.getJobData(this.state.company, this.state.job, year, month).then((jobData) => {
      this.setState({
        data: jobData,
      })
    })

    this.compareJobData(year, month)
  } 

  // compareJobData: retrieves data form a previous month (if available) and compares a % increase.
  compareJobData(year, month) {
    this.getJobMonthYear(this.state.job)
    API.getJobData(this.state.company, this.state.job, year, month).then((currentJobData) => { 
      API.getJobData(this.state.company, this.state.job, year, month - 1).then((prevJobData) => {

        let percentDiffObj = {
          headContract: {}, 
          budget: {},
          profitMarginsValue: {},
          profitMarginsPercent: {},
          cashPosition: {},
          incomeInAdvance: {},
          monthYear: {},
        }
        Object.keys(currentJobData).map(col => {
          Object.keys(currentJobData[col]).map(val => {
            percentDiffObj[col][val] = this.percentDiff(currentJobData[col][val], prevJobData[col][val])
          })
        })
        
        Object.keys(currentJobData.profitMarginsPercent).map(percentCol => {
          percentDiffObj.profitMarginsPercent[percentCol] = this.percentPDiff(parseFloat(currentJobData.profitMarginsPercent[percentCol]), parseFloat(prevJobData.profitMarginsPercent[percentCol]))
        })

        percentDiffObj.profitMarginsValue.costCompletion = this.percentPDiff(parseFloat(currentJobData.profitMarginsValue.costCompletion), parseFloat(prevJobData.profitMarginsValue.costCompletion))
        percentDiffObj.profitMarginsValue.claimsCompletion = this.percentPDiff(parseFloat(currentJobData.profitMarginsValue.claimsCompletion), parseFloat(prevJobData.profitMarginsValue.claimsCompletion))
        
        this.setState({
          percentDiffObj: percentDiffObj,
        });
      })
    })
  }

  // percentageDiff: calculates the percentage difference between 2 values.
  percentDiff(currentValue, prevValue) {
    var percentDiff = ((currentValue - prevValue) / Math.abs(prevValue) * 100.0).toFixed(2);
    
    // check for garbage values due to missing data.
    if (isNaN(percentDiff) || isFinite(percentDiff) === false) {
      percentDiff = "-.--"
    }
    return percentDiff
  }
 
  // percentagePDiff: calculates the percentage difference between 2 percentages.
  percentPDiff(currentPercentage, prevPercentage) {
    var percentDiff = (currentPercentage - prevPercentage).toFixed(2);

    // Check for garbage values or missing data.
    if (isNaN(percentDiff) || isFinite(percentDiff) === false) {
      percentDiff = "-.--"
    }
    return percentDiff
  }
  render () {
    return <div className="JobSummary">
      <div className="edit">
          <Space direction="horizontal" size={12}>
            <Select style={{ width: '150px' }} value={this.state.company} onChange={this.changedCompany}>
              {(this.props.user.vic >= 1) && <Option value="VI">Victoria</Option>}
              {(this.props.user.qld >= 1) && <Option value="QL">Queensland</Option>}
            </Select>
            <Select
              showSearch
              style={{ width: 500 }}
              placeholder="Select a Project"
              optionFilterProp="children"
              onChange={this.changedJob}
            >
              {this.state.jobs.map((i) => <Option value={i.job_number}>{i.title}</Option>)}
            </Select>
          </Space>
        </div>
        {this.state.isLoaded && <div className="">
          <div className="jSummaryTop">
          <div className="chartContainer">
              <h2>Job actuals and forecast</h2>
              <Bar
                  data={this.state.forecastData}
                  width={1000}
                  height={300}
                  options={{
                    events: ['mousemove', 'click'],
                    tooltips: {
                        mode: 'index',
                        intersect: true,
                        callbacks: {
                          label: (tooltipItem, data) => {
                            return `${this.state.forecastData.datasets[tooltipItem.datasetIndex].label} ${parseInt(tooltipItem.yLabel) >= 0 ? '' : '-'}$${Math.abs(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                          }
                        }
                    },
                    responsive: true,
                    scales: {
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                callback: function (value) {
                                    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                },
                                beginAtZero: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Amount $'
                            }
                        }],
                        xAxes: [{
                            stacked: true,
                            scaleLabel: {
                              display: true,
                              labelString: 'Time' 
                            },
                            ticks: {
                              beginAtZero: true
                            }
                        }],
                    },
                    'onHover': (evt, item) => {
                      document.getElementsByTagName('canvas')[0].style.cursor = item.length !== 0 ? 'pointer' : 'default'
                    }
                }}
              />
            </div>
            <div className="chartContainer">
              <h2>Actual cashflow</h2>
              <Bar
                  data={this.state.summaryData}
                  width={1000}
                  height={300}
                  options={{
                    events: ['mousemove', 'click'],
                    tooltips: {
                        mode: 'index',
                        intersect: true,
                        callbacks: {
                          label: (tooltipItem, data) => {
                            return `${this.state.summaryData.datasets[tooltipItem.datasetIndex].label} ${parseInt(tooltipItem.yLabel) >= 0 ? '' : '-'}$${Math.abs(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                          }
                        }
                    },
                    responsive: true,
                    scales: {
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                callback: function (value) {
                                    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                },
                                beginAtZero: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Amount $'
                            }
                        }],
                        xAxes: [{
                            stacked: true,
                            scaleLabel: {
                              display: true,
                              labelString: 'Time' 
                            },
                            ticks: {
                              beginAtZero: true
                            }
                        }],
                    },
                    'onHover': (evt, item) => {
                      document.getElementsByTagName('canvas')[0].style.cursor = item.length !== 0 ? 'pointer' : 'default'
                    }
                }}
              />
            </div>
          </div>
          <div className="card-container costpositionparent">
          <Tabs type="card">
            <TabPane forceRender={true} tab="Report Summary" key="1">
              {/* Drop Down menu. */}
              <DatePicker className="datePicker" picker="month" defaultValue={moment()} defaultPickerValue={moment()} onChange={this.dateChanged}/>
              
              <div className="jSummaryContent spaceBetween">
                <div className="card">
                  <h2>Head Contract</h2>

                  <h3>Original Contract Sum</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.headContract.originalContractSum)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.originalContractSum) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.originalContractSum)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Approved Variations</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.headContract.approvedVariation)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.approvedVariation) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.approvedVariation)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Pending Variations</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.headContract.pendingVariation)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.pendingVariation) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.pendingVariation)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <h3>Updated Contractor Sum</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.headContract.updatedContractSum)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.updatedContractSum) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.updatedContractSum)} %
                      </span>
                    </Col>
                  </Row>
                  <hr/>

                  <h3>Claims</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.headContract.claims)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.claims) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.claims)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <h3>Pending Claims</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.headContract.pendingClaims)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.headContract.pendingClaims) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.headContract.pendingClaims)} %
                      </span>
                    </Col>
                  </Row>
                  
                </div>
                <div className="card">
                  <h2>Budget</h2>

                  <h3>Original Budget</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.budget.originalBudget)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.originalBudget) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.budget.originalBudget)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <h3>Approved Variations</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.budget.approvedVariation)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.approvedVariation) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.budget.approvedVariation)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <h3>Pending Variations</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.budget.pendingVariation)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.pendingVariation) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.budget.pendingVariation)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <h3>Updated Budget</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{formatter.format(this.state.data.budget.updatedBudget)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.updatedBudget) === -1 ? "red" : "green"}}>
                        {(this.state.percentDiffObj.budget.updatedBudget)} %
                      </span>
                    </Col>
                  </Row>
                  
                  <hr/>

                  <h3>Committed Costs</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.budget.committedCost)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.committedCost) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.budget.committedCost)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Anticipated Costs</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.budget.anticipatedCost)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.anticipatedCost) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.budget.anticipatedCost)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Forecast Final Cost</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.budget.forecastFinalCost)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.budget.forecastFinalCost) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.budget.forecastFinalCost)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Contingency</h3>
                  <span> - </span>
                </div>
                <div className="card">
                  <h2>Profit Margins $</h2>
                  
                  <h3>Original Margin</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.profitMarginsValue.originalMargin)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.originalMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.originalMargin)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Revised Margin</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.profitMarginsValue.revisedMargin)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.revisedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.revisedMargin)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Expected Margin</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.profitMarginsValue.expectedMargin)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.expectedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.expectedMargin)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Earned Margin</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.profitMarginsValue.earnedMargin)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.earnedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.earnedMargin)} %
                      </span>
                    </Col>
                  </Row>

                  <hr/>

                  <h2>Completion % To Date</h2>
                  
                  <h3>Cost Completion</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{this.state.data.profitMarginsValue.costCompletion}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.costCompletion) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.costCompletion)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Claims Completion</h3>
                  <Row justify="space-between">
                    <Col>
                    <span>{this.state.data.profitMarginsValue.claimsCompletion}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsValue.claimsCompletion) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsValue.claimsCompletion)} %
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="card">
                <h2>Profit Margins %</h2>

                <h3>Revised Margin</h3>
                <Row justify="space-between">
                    <Col>
                      <span>{this.state.data.profitMarginsPercent.revisedMargin}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsPercent.revisedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsPercent.revisedMargin)} %
                      </span>
                    </Col>
                </Row>

                <h3>Expected Margin</h3>
                <Row justify="space-between">
                    <Col>
                      <span>{this.state.data.profitMarginsPercent.expectedMargin}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsPercent.expectedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsPercent.expectedMargin)} %
                      </span>
                    </Col>
                </Row>

                <h3>Earned Margin</h3>
                <Row justify="space-between">
                    <Col>
                      <span>{this.state.data.profitMarginsPercent.earnedMargin}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.profitMarginsPercent.earnedMargin) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.profitMarginsPercent.earnedMargin)} %
                      </span>
                    </Col>
                </Row>
                </div>
              </div>
              <div className="jSummaryContent borderTop">
                <div className="card">
                  <h2>Cash Position</h2>

                  <h3>Actual Cash Out</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.cashPosition.actualCashOut)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.cashPosition.actualCashOut) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.cashPosition.actualCashOut)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Actual Cash In</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.cashPosition.actualCashIn)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.cashPosition.actualCashIn) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.cashPosition.actualCashIn)} %
                      </span>
                    </Col>
                  </Row>

                  <h3>Net Cash Flows</h3>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.cashPosition.netCashFlow)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.cashPosition.netCashFlow) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.cashPosition.netCashFlow)} %
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="card">
                  <h2>WIP / Income in Advance</h2>
                  <Row justify="space-between">
                    <Col>
                      <span>{formatter.format(this.state.data.incomeInAdvance.value)}</span>
                    </Col>
                    <Col>
                      <span style={{color: Math.sign(this.state.percentDiffObj.incomeInAdvance.value) === -1 ? "red" : "green"}}>
                      {(this.state.percentDiffObj.incomeInAdvance.value)} %
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="jSummaryContent borderTop">
                <div className="card-full">
                  <h2>Cost & Claims Summary</h2>
                  {this.state.costClaimsSummary.financialYears.map(financialYear => (
                    <React.Fragment>
                      <h4>FY{financialYear}</h4>
                      <Table columns={this.state.costClaimsSummary.columns[this.state.costClaimsSummary.financialYears.indexOf(financialYear)]}
                      dataSource={[
                        this.state.costClaimsSummary.data[0][financialYear],
                        this.state.costClaimsSummary.data[1][financialYear],
                      ]}
                      bordered
                      pagination={false}/>
                    </React.Fragment>
                  ))}
                </div>
                <div className="card-full">
                  <h2>Job Risk Opportunity</h2>
                  <JobRiskOpportunitySummary company={this.state.company} job={this.state.job} />
                </div>
              </div>
              <div className='jSummaryContent borderTop'> 
                <div className="card-full">
                  <h2>Critical Dates Summary</h2>
                  <CriticalDatesSummary company={this.state.company} job={this.state.job} />
                </div>

              </div>
            </TabPane>
            <TabPane tab="Cost Positions" key="2">
              <Table columns={this.state.costColumns}
                dataSource={this.state.costItemData.costItems}
                bordered
                pagination={false}/>
            </TabPane>
            <TabPane tab="Claim Positions" key="3">
              <Table columns={this.state.claimColumns}
                  dataSource={this.state.progressClaimData.progressClaims}
                  bordered
                  pagination={false}/>
            </TabPane>
            <TabPane forceRender={true} tab="Critical Dates" key="4">
                <CriticalDates company={this.state.company} job={this.state.job} />
            </TabPane>
            <TabPane forceRender={true} tab="Risk & Opportunity" key="5">
              <RiskOpportunity key={this.state.company+this.state.job} company={this.state.company} job={this.state.job} />
            </TabPane>
          </Tabs>
        </div>
        </div>}
    </div>
  }

}
 
const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(JobSummary);
