import React, { useEffect } from "react";
import * as API from '../api/api';
import { Select } from "antd";
import UserAccessPanel from "../components/userAccess/UserAccessPanel";
import './UserAccess.scss';

const { Option } = Select;

export default function ManageUserAccess(props) {
    const [users, setUsers] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    
    useEffect(() => {
        API.getAllUsers().then((res) => {
            setUsers(res.users);
            setRoles(res.roles);
        })
    }, []);

    console.log(props)

    return (
        <div className="display-panel">
            <div className="user-access-container">
            <span className="user-access-label">Select a User:</span>
                <Select 
                    style={{ width: 200 }}
                    placeholder="Select a User via Search"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                        setSelectedUser(value);
                    }}
                >
                    {Object.keys(users).map((user) => {
                        return (
                            <Option value={users[user].email}>{users[user].email}</Option>
                        )
                    })}
                </Select>
            </div>
            <UserAccessPanel 
                selectedUser={selectedUser} 
                users={users} 
                roles={roles} 
            />
        </div>
    )
}