import {React, useState, useEffect} from "react";
import * as API from '../api/api';
import './RiskOpportunity.scss'
import { Popconfirm, Button, Select, Input, Table } from "antd";

const { Option } = Select;
const confText = "Do you want to delete this job?";

function RiskOpportunityV2(props) {
    // State Variables:
    const [data, setData] = useState([]);

    useEffect(() => {
        API.getJRO(props.company, props.job).then(res => {
            console.log(res.resData)
            setData(res.resData);
        })
    }, [props.company, props.job]);
    
    const createJRO = () => {
        API.addJRO(props.company, props.job).then(updateRenderedData());
    }

    const updateRenderedData = () => {
        API.getJRO(props.company, props.job).then(res => {
            console.log("Updated!");
            setData(res.resData);
        })
    }

    const cols = [
        {
            title: 'Description', dataIndex: 'desc', key: 'desc',
            render: (_, record) => (
                <Input 
                    key={record.id + "desc"}
                    placeholder='Input Description'
                    defaultValue={record.desc}
                
                    onChange={(inputStr) => {
                        record.desc = inputStr.target.value;
                        API.updateJRO(props.company, props.job, record.id, record);
                    }}
                />
            )
        }, 
        {
            title: 'Worst', dataIndex: 'worst', key: 'worst',
            render: (_, record) => (
                <Input 
                    key={record.id + "worst"}
                    placeholder="Worst $"
                    defaultValue={record.worst}

                    onChange={(inputStr) => {
                        record.worst = inputStr.target.value;
                        API.updateJRO(props.company, props.job, record.id, record);
                    }}
                />
            )
        }, 
        {
            title: 'Likely', dataIndex: 'likely', key: 'likely',
            render: (_, record) => (
                <Input 
                    key={record.id + "likely"}
                    placeholder="Likely $"
                    defaultValue={record.likely}
                    
                    onChange={(inputStr) => {
                        record.likely = inputStr.target.value;
                        API.updateJRO(props.company, props.job, record.id, record);
                    }}
                />
            )
        },
        {
            title: 'Best', dataIndex: 'best', key:'best',
            render: (_, record) => (
                <Input 
                    key={record.id + "best"}
                    placeholder="Best $"
                    defaultValue={record.best}
                    
                    onChange={(inputStr) => {
                        record.best = inputStr.target.value;
                        API.updateJRO(props.company, props.job, record.id, record);
                    }}
                />
            )
        },
        {
            title: 'Risk/Opportunity', dataIndex: 'rop', key:'rop',
            render: (_, record) => (
                <Select 
                    key={record.id + "rop"} 
                    defaultValue={record.rop}
                    style={{minWidth: 200}} 
                    onChange={(value) => {
                        record.rop = value;
                        API.updateJRO(props.company, props.job, record.id, record).then(updateRenderedData);
                    }}  
                >
                    <Option value="Risk">Risk</Option>
                    <Option value="Opportunity">Opportunity</Option>
                </Select>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Popconfirm
                    title = {confText}
                    onConfirm={() => {
                        API.deleteJRO(props.company, props.job, record.id).then(updateRenderedData());
                    }} 
                    okText="Yes"
                    cancelText="Cancel"  
                >
                    <Button type="primary">Delete</Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <div className="card-full">
            <h3 className="title">Job Risk/Opportunity Entry</h3>
            <Button className="button" style={ {marginLeft: '10px'}} onClick={createJRO}>Add Risk Opportunity</Button>
            <Table
            className="table" 
            columns={cols} 
            dataSource={data} 
            rowKey={record => record.id}
            pagination={false}
            bordered 
            />
        </div>
        
    )
}

export default RiskOpportunityV2;