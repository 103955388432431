import axios from 'axios';
import { fetchUserPending, fetchUserSuccess, fetchUserError} from '../redux/User/user.actions';

const developmentPrefix = '';
// axios.interceptors.response.use(
//   response => response,
//   error => {
//     const {status} = error.response;
//     if (status === 401 && error.response.config.url !== '/auth/email') {
//       console.log('Not Authenticated');
//       //Redirect to the landing page.
//       window.location = '/';
//     }
//     return Promise.reject(error);
//  }
// );

/**
 * Returns the Generic User Information
 */
export function getUser() {
    return dispatch => {
    dispatch(fetchUserPending());
    axios.get(`${developmentPrefix}/user/state`)
      .then((response) => {
        if(response.data.authenticated) {
          dispatch(fetchUserSuccess(response.data.user));
        } else {
          dispatch(fetchUserError(response?.data || "Unknown error"))
        }
        return response.data.user;
      })
      .catch((error) => {
        dispatch(fetchUserError(error?.response?.data || "Unknown error"))
      });
    }
}

/**
 * Looks up the API Keys for the User
 */
export function getReportData(period, beginBack, endFront, company) {
  return axios.get(`${developmentPrefix}/api/?period=${period}&beginBack=${beginBack}&endFront=${endFront}&company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {};
    });
}


export function getDataEntryRecords(date, company, type = "manual") {
  return axios.get(`${developmentPrefix}/api/data/${type == 'manual' ? 'entry' : 'recurringEntry'}?year=${date.getFullYear()}&month=${date.getMonth() + 1}&company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

// add manual data.
export function updateDataEntryRecord(id, updated) {
  return axios.post(`${developmentPrefix}/api/data/entry/${id}`, updated)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateRecurringDataEntryRecord(id, updated) {
  return axios.post(`${developmentPrefix}/api/data/recurringEntry/${id}`, updated)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function addDataEntryRecord(date, company, type = "manual") {
  return axios.post(`${developmentPrefix}/api/data/${type == 'manual' ? 'entry' : 'recurringEntry'}?year=${date.getFullYear()}&month=${date.getMonth() + 1}&company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}


export function deleteDataEntryRecord(id) {
  return axios.delete(`${developmentPrefix}/api/data/entry/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function deleteRecurringDataEntryRecord(id) {
  return axios.delete(`${developmentPrefix}/api/data/recurringEntry/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getDrilldownData(date, company) {
  return axios.post(`${developmentPrefix}/api/drilldown`, {
    date,
    company
  }).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}


/**
 * Project / Claim
 */

export function getProjectData(company, job) {
  return axios.get(`${developmentPrefix}/api/project/?company=${company}&job=${job}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function addClaimRecord(company, job) {
  return axios.post(`${developmentPrefix}/api/project/?company=${company}&job=${job}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function deleteClaimRecord(uuid) {
  return axios.delete(`${developmentPrefix}/api/project/${uuid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateClaimRecord(uuid, data) {
  return axios.post(`${developmentPrefix}/api/project/${uuid}`, data)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function getJobs(company) {
  return axios.get(`${developmentPrefix}/api/project/jobs?company=${company}`)
    .then((response) => {
      console.log(response.data)
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getChartOfAccounts(company) {
  return axios.get(`${developmentPrefix}/settings/charts?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateCompanyAccountWatchlist(company, ref, value) {
  return axios.post(`${developmentPrefix}/settings/watchlist`, {company, account_ref: ref, value})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateCompanyAccountBankBalance(company, ref, value) {
  return axios.post(`${developmentPrefix}/settings/bank`, {company, account_ref: ref, value})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function updateCompanyAccountBalanceSheetMapping(company, ref, value) {
  return axios.post(`${developmentPrefix}/settings/balancesheetmapping`, {company, account_ref: ref, value})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}


export function updateCompanyAccountComparativeMapping(company, ref, value) {
  return axios.post(`${developmentPrefix}/settings/comparativemapping`, {company, account_ref: ref, value})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function updateCompanyAccountSummary(company, ref, value) {
  return axios.post(`${developmentPrefix}/settings/summary`, {company, account_ref: ref, value})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

// Users

export function getAllUsers() {
  return axios.get(`${developmentPrefix}/settings/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getUserByEmail(email) {
  return axios.get(`${developmentPrefix}/settings/user/` + email)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getUserAccessByEmail(email) {
  return axios.get(`${developmentPrefix}/settings/user/access/` + email)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateUserAccessByEmail(email, access) {
  return axios.post(`${developmentPrefix}/settings/user/access`, {email, access})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateUsers(email, fields) {
  return axios.post(`${developmentPrefix}/settings/user`, {email, fields})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function deleteUser(email) {
  return axios.delete(`${developmentPrefix}/settings/user/` + email)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function addUser(email, accountType, accountPassword) {
  return axios.put(`${developmentPrefix}/settings/user`, {email, accountType, accountPassword})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

// Tender Start Up Queries:
export function addStartupReport(report) {
  return axios.post(`${developmentPrefix}/api/startup/addReport`, {report}).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function getStartupReports() {
  return axios.get(`${developmentPrefix}/api/startup/getReports/`).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// Add New Tender: Queries an INSERT to create a new tender col. 
export function addTender(projectName, projectCode, projectStatus, dueDate, subDate) {
  return axios.post(`${developmentPrefix}/api/tender/createTender?projectName=${projectName}&projectCode=${projectCode}&status=${projectStatus}&dueDate=${dueDate}&subDate=${subDate}`).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// updateTender: Queries an UPDATE to the data to pass all required values after creation.
export function updateTender(id, updatedTender) {
  console.log("API Call w/ Updated Tender.")
  console.log(updatedTender)
  return axios.post(`${developmentPrefix}/api/tender/updateTender/${id}`, updatedTender)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

// deleteTender: Queries a DELETE to TenderTracking and removes the tender with spec ID.
export function deleteTender(id) {
  return axios.delete(`${developmentPrefix}/api/tender/deleteTender/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[]
    })
}

// getTenderID: Queries a GET based on ProjectName and ProjectCode - returns an UUID.
export function getTenderByID(id) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderID/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[]
    })
}

// getAllTenders: Queries a GET to retrieve all Tender Data.
export function getAllTenders() {
  return axios.get(`${developmentPrefix}/api/tender/getAllTenders`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[]
    })
}

export function getTenderDataPrimary() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderPrimary`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[]
    })
}

// getTenderValueByType: Selects the submittedValue of all tenders with the same type. Returns in an object array.
export function getTenderValueViaType(type) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderValue?type=${type}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderSector: returns an object array of all available tender sectors mapped via id.
export function getTenderSector() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderSector`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderAllocated: returns an object array of all available tender allocations mapped via id.
export function getTenderAllocated() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderAllocated`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderCategory: returns an object array of all available tender categories mapped via id.
export function getTenderCategory() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderCategory`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderStatusCount: returns ALL current stored statuses and a count variable.
export function getTenderStatusCount() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderStatusCount`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderStatusCountHistorical: returns ALL current stored statuses and a count variable based on a supplied date period.
export function getTenderStatusCountHistorical(initDate, finalDate) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderStatusCountHistorical?initDate=${initDate}&finalDate=${finalDate}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderTypeSub: returns both the type and total submitted value.
export function getTenderTypeSub() {
  return axios.get(`${developmentPrefix}/api/tender/getTenderTypeSub`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderTypeSubHistorical: returns both the type and total submitted value based on a submitted date period.
export function getTenderTypeSubHistorical(initDate, finalDate) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderTypeSubHistorical?initDate=${initDate}&finalDate=${finalDate}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderSectSumViaStatus: returns the type and value based on tender status.
export function getTenderSectViaStatus(status) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderSectSumViaStatus?status=${status}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// getTenderSectSumViaStatus: returns the type and value based on tender status between a specified start and end date.
export function getTenderSectViaStatusHistorical(status, initDate, finalDate) {
  return axios.get(`${developmentPrefix}/api/tender/getTenderSectSumViaStatus?status=${status}&initDate=${initDate}&finalDate=${finalDate}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return[];
    })
}

// Company Information
export function addCompanyInformation(date) {
  return axios.post(`${developmentPrefix}/api/company/entry?year=${date.getFullYear()}&month=${date.getMonth() + 1}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getCompanyInformation(orderByCreated) {
  return axios.get(`${developmentPrefix}/api/company/entry?orderByCreated=${orderByCreated}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateCompanyInformation(id, updated) {
  return axios.post(`${developmentPrefix}/api/company/entry/${id}`, updated)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function deleteCompanyInformationRecord(id) {
  return axios.delete(`${developmentPrefix}/api/company/entry/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

// getJobData: Retrieves job data @ the current Year, Month specified.
export function getJobData(company, job, year, month) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}?company=${company}&year=${year}&month=${month}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getJRO(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/JRO?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getSumRisk(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/sumRisk?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// getJobCritDates: retieves all data in the critjob table.
export function getJobCritDates(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/critdate?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getSumOpportunity(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/sumOpportunity?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function addJRO(company, job) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/JRO?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function deleteJRO(company, job, id) {
  return axios.delete(`${developmentPrefix}/api/project/job/${job}/deleteJRO/${id}?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// addJobCritDates: adds a new entry into the critjob table.
export function addJobCritDates(company, job) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/critdate?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

export function updateJRO(company, job, id, updatedJob) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/updateJRO/${id}?company=${company}`, updatedJob)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// deleteJobCritDates: deletes a entry from the db.
export function deleteJobCritDates(company, job, id) {
  return axios.delete(`${developmentPrefix}/api/project/job/${job}/delete/${id}?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// updateJobCritDates: updates the db with inputted data.
export function updateJobCritDates(company, job, id, updatedJob) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/update/${id}?company=${company}`, updatedJob)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
}

// getJobMonthYear: Retrieves the month and year data from a job via number.
export function getJobMonthYear(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}?company=${company}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getJobCostItemData(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/costitems?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function getProgressClaimsData(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/progressclaims?company=${company}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export function updateJobCostItemClaimPer(company, job, costClaimPeriod, costItemName, costTypeCode, costClaimPer) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/costitemper?company=${company}`, {
    costClaimPeriod,
    costItemName,
    costTypeCode,
    costClaimPer
    })
    .then((response) => {
      return {err: false, ...response.data};
    })
    .catch((error) => {
      return {err: true, ...error};
    });
}

export function upgradeProgressClaimClaimPer(company, job, progressClaimPeriod, progressItemNumber, progressClaimPer) {
  return axios.post(`${developmentPrefix}/api/project/job/${job}/progressclaimsper?company=${company}`, {
    progressClaimPeriod,
    progressItemNumber,
    progressClaimPer
    })
    .then((response) => {
      return {err: false, ...response.data};
    })
    .catch((error) => {
      return {err: true, ...error};
    });
}

export function getJobSummaryData(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/summary?company=${company}`)
    .then((response) => {
      return {err: false, ...response.data};
    })
    .catch((error) => {
      return {err: true, ...error};
    });
}

export function getJobForecastData(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/forecast?company=${company}`)
    .then((response) => {
      return {err: false, ...response.data};
    })
    .catch((error) => {
      return {err: true, ...error};
    });
}

export function getCostClaimsSummary(company, job) {
  return axios.get(`${developmentPrefix}/api/project/job/${job}/costclaimsummary?company=${company}`)
  .then((response) => {
    return {err: false, ...response.data};
  })
  .catch((error) => {
    return {err: true, ...error};
  });
}

export function getTotalCostClaimsSummary(company) {
  return axios.get(`${developmentPrefix}/api/project/totalcostclaimsummary?company=${company}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return {err: true, ...error};
  });
}

export function loginUser(email, password) {
  console.log('Login..', email, password);
  return axios.post(`${developmentPrefix}/auth/email`, {username: email, password}).then((response) => {
    return response.data.status;
  })
  .catch((error) => {
    return false;
  });
}


// Force Data Sync
export function dataSync(email, password) {
  return axios.get(`${developmentPrefix}/data/sync`).then((response) => {
    return response.data.status;
  })
  .catch((error) => {
    return false;
  });
}

