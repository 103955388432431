import React from 'react'
import {Form, Select, Input, Checkbox, Button, Modal } from 'antd';
import './GoNoGo.scss'

const Option = Select.Option;

export default function GoNoGo() {
    const [checkValue, setCheckValue] = React.useState('');
    const [isVisible, setIsVisible] = React.useState(false)

    const [form] = Form.useForm();
    const checkboxes = [
        { label: "Approved", value: "Approved" },
        { label: "Rejected", value: "Rejected" },
    ]

    const onValChange = (changedValues, allValues) => { 
        const fieldName = Object.keys(changedValues)[0];
        if (
            fieldName === "sandbox-yn" ||
            fieldName === "sandbox-how" ||
            fieldName === "sandbox-val" ||
            fieldName === "sandbox-location" ||
            fieldName === "client-type" ||
            fieldName === "project-margin" ||
            fieldName === "project-doc-quality" ||
            fieldName === "competition-num" || 
            fieldName === "competition-weight"
        ) {
            const sandboxYn = 
                parseFloat(changedValues["sandbox-yn"] || allValues["sandbox-yn"] || "") || 0;
            const sandboxHow =
                parseFloat(changedValues["sandbox-how"] || allValues["sandbox-how"] || "") || 0;
            const sandboxVal =
                parseFloat(changedValues["sandbox-val"] || allValues["sandbox-val"] || "") || 0;
            const sandboxLocation =
                parseFloat(changedValues["sandbox-location"] || allValues["sandbox-location"] || "") || 0;
            const clientType =
                parseFloat(changedValues["client-type"] || allValues["client-type"] || "") || 0;
            const projectMargin =
                parseFloat(changedValues["project-margin"] || allValues["project-margin"] || "") || 0;
            const projectDocQuality =
                parseFloat(changedValues["project-doc-quality"] || allValues["project-doc-quality"] || "") || 0;
            const competitionNum =
                parseFloat(changedValues["competition-num"] || allValues["competition-num"] || "") || 0;
            const competitionWeight =
                parseFloat(changedValues["competition-weight"] || allValues["competition-weight"] || "") || 0;

            if (sandboxYn === "No") {
                form.setFieldsValue({"go-no-go-val": "Project Doesn't Fit Sandbox"});
                form.setFieldsValue({"go-no-go-percentage": "Nil %"});
            } else {
                const total = (sandboxYn) + (sandboxHow) + (sandboxVal) 
                + (sandboxLocation) + (clientType) + (projectMargin) 
                + (projectDocQuality) + (competitionNum) + (competitionWeight)

                const totalPercentage = (total / 45.00) * 100.00;

                form.setFieldsValue({ "go-no-go-val": (sandboxYn) + (sandboxHow) + (sandboxVal) 
                + (sandboxLocation) + (clientType) + (projectMargin) 
                + (projectDocQuality) + (competitionNum) + (competitionWeight) + "/45" });
                form.setFieldsValue({ "go-no-go-percentage": totalPercentage.toFixed(2) + "%" });
            } 
        }
    }

    const onCheckChange = (checkedValues) => {
        setCheckValue(checkedValues.target.value);
        console.log('checked = ', checkedValues.target.value);
    }

    const onSubmit = (values) => {
        setIsVisible(true);
        console.log('Received values of form: ', values);
        console.log('Approval = ', checkValue);
        form.resetFields();
    }

    return (
        <>
            <div className='form-container'>
                
                <Form name="sandbox" form={form} scrollToFirstError onValuesChange={onValChange} onFinish={onSubmit}>
                <h3>Project Sandbox Options</h3>
                    <Form.Item name="sandbox-yn" label="How does this project fit inside our sandbox?" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear className='input-select'>
                            <Option value={5}>Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sandbox-how-cat" label="How? (Category)" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear className='input-select'>
                            <Option value="Education">Education</Option>
                            <Option value="Industrial">Industrial</Option>
                            <Option value="Retail">Retail</Option>
                            <Option value="Fitness & Lifestyle">Fitness & Lifestyle</Option>
                            <Option value="Hospitality">Hospitality</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sandbox-how" label="How? (Type)" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option key={1} value={5}>New Build</Option>
                            <Option key={2} value={4}>Fitout</Option>
                            <Option key={3} value={3}>Live Environment</Option>
                            <Option key={4} value={3}>Staged</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sandbox-val" label="Project Value" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear className='input-select'>
                            <Option key={1} value={5}>$2.5M-$7M</Option>
                            <Option key={2} value={4}>$500K-$2.49M</Option>
                            <Option key={3} value={3}>$100K-$499K</Option>
                            <Option key={4} value={3}>$7.1-10M</Option>
                            <Option key={5} value={2}>{'>'}$100K</Option>
                            <Option key={6} value={2}>$10M-$15M</Option>
                            <Option key={7} value={1}>{'>'}$15M</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sandbox-location" label="Project Location" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option key={1} value={5}>$2.5M-$7M</Option>
                            <Option key={2} value={4}>$500K-$2.49M</Option>
                            <Option key={3} value={3}>$100K-$499K</Option>
                            <Option key={4} value={3}>$7.1-10M</Option>
                            <Option key={5} value={2}>{'>'}$100K</Option>
                            <Option key={6} value={2}>$10M-$15M</Option>
                            <Option key={7} value={1}>{'>'}$15M</Option>
                        </Select>
                    </Form.Item>
                <h3>Project Client Options</h3>
                    <Form.Item name="client-type" label="Type of Client" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option value={5}>Government/Public</Option>
                            <Option value={4}>Repeat / National Corporation</Option>
                            <Option value={3}>Large Private Corporation</Option>
                            <Option value={2}>Small Private Corporation</Option>
                            <Option value={1}>Individual Developer</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="client-repeat" label="If repeat, was it a positive and profitable experience?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="client-influence" label="Can we influence the outcome through our relationship?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="client-target" label="Is the client a strategic target (ie national, pipeline etc)">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                <h3>Project Options</h3>
                    <Form.Item name="project-margin" label="Potential Margin" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option value={5}>10% or {'>'}$300K</Option>
                            <Option value={4}>6-9% or {'>'}$150K</Option>
                            <Option value={3}>5% or {'>'}$100K</Option>
                            <Option value={2}>3-4% or {'<'}$100K</Option>
                            <Option value={1}>{'<'}2%</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="project-similarity" label="Has ICM delivered similar projects before?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="project-target" label="Is this a project we are targeting to expand our experience?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="project-complexity" label="Is the complexity of the project minimal?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="project-doc-quality" label="What is the quality of documentation and contract type?" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option value={5}>Cost Plus</Option>
                            <Option value={4}>Fully Doc/ Construct Only</Option>
                            <Option value={3}>High Docs/ D&C</Option>
                            <Option value={2}>Preliminary/ D&C</Option>
                            <Option value={1}>Low Doc/ D&C</Option>
                        </Select>
                    </Form.Item>
                <h3>Project Timing Option</h3>
                    <Form.Item name="timing-bid" label="Is the bid program realistic?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="timing-build" label="Is the build time realistic?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                <h3>Project Competition Options</h3>
                    <Form.Item name="competition-num" label="Number of other tenderers" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option value={5}>1</Option>
                            <Option value={4}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={2}>4</Option>
                            <Option value={1}>Open</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="competition-strength" label="How does our strength compare to our competition?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="competition-weight" label="Price vs Non Price Weighting" rules={[{ required: true, message: 'This Field is Required!' }]}>
                        <Select allowClear>
                            <Option value={5}>{'>'}60% Non Price</Option>
                            <Option value={4}>40-60% Non Price</Option>
                            <Option value={3}>20-40% Non Price</Option>
                            <Option value={2}>1-20% Non Price</Option>
                            <Option value={1}>100% Price</Option>
                        </Select>
                    </Form.Item>
                <h3>Our Team Options</h3>
                    <Form.Item name="team-capable" label="Is the team capable / experienced to deliver the project?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="team-resourced" label="Is the team adequately resourced for the delivery model?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="team-additional-resources" label="Does ICM need the job to fill resources up?">
                        <Select allowClear>
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>
                <h3>Project Go-No-Go Summary</h3>
                    <Form.Item name="go-no-go-val" label="Final Score">
                        <Input readOnly/>
                    </Form.Item>
                    <Form.Item name="go-no-go-percentage" label="Final Percentage">
                        <Input readOnly/>
                    </Form.Item>
                    <span>Tender Score card to be assessed and addressed without bias and with no emotion</span>
                    <div className="blurb-container">
                        <div className="left-container">
                            <span>More than 80%</span>
                            <span>60% to 89%</span>
                            <span>Less than 60%</span>
                        </div>
                        <div className="right-container">
                            <span>Project must be considered.</span>
                            <span>Project should be considered by SLT with consideration of YES / NO criteria</span>
                            <span>Project should not be considered</span>
                        </div>
                    </div>
                    <div className="check-container">
                    {checkboxes.map((item) => {
                        return(
                            <Checkbox key={item.label} value={item.value} onChange={onCheckChange} checked={item.value == checkValue}>
                                {item.label}
                            </Checkbox>
                        )
                    })}
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        
                        <Modal title="Tender Go-No-Go Form" visible={isVisible} onOk={() => {setIsVisible(false)}} onCancel={() => {setIsVisible(false)}}>
                            <p>Your tender go-no-go form has been successfully submitted.</p>
                        </Modal>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}