import { Component, useContext, useState, useEffect, useRef } from "react";
import React from 'react';
import './ARAPDataEntry.scss';
import * as API from '../api/api';
import moment from 'moment';
import _ from 'lodash';
import { DatePicker, Select, Space, Table, Button, Input, Form, InputNumber } from 'antd';
import 'antd/dist/antd.css';

const { Option } = Select;
const EditableContext = React.createContext(null);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


class ARAPDataEntry extends Component {

  constructor(props) {
    super();
    this.state = {
      manualRows: [],
      recurringRows: [],
      company: 'QL'
    };

    const deleteRow = (row) => {
      API.deleteDataEntryRecord(row.id);
        //Reset month to reload
      this.setMonth(this.state.currentMonth);
    }

    const deleteRecurringRow = (row) => {
      API.deleteRecurringDataEntryRecord(row.id);

      //Reset month to reload
      this.setMonth(this.state.currentMonth);
    }

    this.recurringColumns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        editable: true,
        render: (_, record) => {
          return <Input
            key={record.id + "type"}
            onChange={(str) => { 
              record.type = str.target.value;
              API.updateRecurringDataEntryRecord(record.id, record).then((res) => {
                //Reset month to reload
                this.setMonth(this.state.currentMonth);
                return true;
              });
            }}
            defaultValue={record.type}
          />
        }
      },
      {
        title: 'Forecast Date',
        dataIndex: 'forecastdate',
        key: 'forecastdate',
        render: (_, record) => {
          return <DatePicker
            format="DD-MM-YYYY"
            key={record.id + "forecastdate"}
            onChange={(dt, dtStr) => {
              if(dtStr !== '') {
                record.forecastdate = dtStr;
                API.updateRecurringDataEntryRecord(record.id, record).then((res) => {
                  //Reset month to reload
                  this.setMonth(this.state.currentMonth);
                  return true;
                });
              }
            }}
            value={moment(record.forecastdate, 'DD/MM/YYYY')}
            format="DD/MM/YYYY"
            allowClear={false}
            />  
        }
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) => {
          return <InputNumber
            key={record.id + "amount"}
            defaultValue={record.amount}
            formatter={value => `${parseFloat(value) >= 0 ? '': '-'}$${Math.abs(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => {
              record.amount = value;
              API.updateRecurringDataEntryRecord(record.id, record).then((res) => {
                //Reset month to reload
                this.setMonth(this.state.currentMonth);
                return true;
              });
            }}
            style={{minWidth: 150}}
            stringMode
          />
        }
      },
      {
        title: 'Frequency',
        dataIndex: 'frequency',
        key: 'frequency',
        render: (_, record) => {
          return <Select
            key={record.id + "frequency"}
            defaultValue={record.frequency}
            style={{minWidth: 100}}
            onChange={(value) => {
              record.frequency = value;
              API.updateRecurringDataEntryRecord(record.id, record).then((res) => {
                //Reset month to reload
                this.setMonth(this.state.currentMonth);
                return true;
              });
            }}
          >
            <Option value="Monthly">Monthly</Option>
            <Option value="Quarterly">Quarterly</Option>
            <Option value="Annually">Annually</Option>
            <Option value="Fortnightly">14 Days</Option>
          </Select>
        }
      },
      {
        title: 'Next Recurrence',
        dataIndex: 'nextrecurrence',
        key: 'nextrecurrence',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => {
          return <Button className="delete" onClick={() => deleteRecurringRow(record)}>Delete</Button>;
        }
      }
    ];

    this.manualColumns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        editable: true,
        render: (_, record) => {
          return <Input
            key={record.id + "type"}
            onChange={(str) => { 
              record.type = str.target.value;
              API.updateDataEntryRecord(record.id, record).then((res) => {
                //Reset month to reload
                this.setMonth(this.state.currentMonth);
                return true;
              });
            }}
            defaultValue={record.type}
          />
        }
      },
      {
        title: 'Forecast Date',
        dataIndex: 'forecastdate',
        key: 'forecastdate',
        render: (_, record) => {
          return <DatePicker
            format="DD-MM-YYYY"
            key={record.id + "forecastdate"}
            onChange={(dt, dtStr) => {
              if(dtStr !== '') {
                record.forecastdate = dtStr;
                API.updateDataEntryRecord(record.id, record).then((res) => {
                  //Reset month to reload
                  this.setMonth(this.state.currentMonth);
                  return true;
                });
              }
            }}
            value={moment(record.forecastdate, 'DD/MM/YYYY')}
            format="DD/MM/YYYY"
            allowClear={false}
            />  
        }
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) => {
          return <InputNumber
            key={record.id + "amount"}
            defaultValue={record.amount}
            formatter={value => `${parseFloat(value) > 0 ? '': '-'}$${Math.abs(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => {
              record.amount = value;
              API.updateDataEntryRecord(record.id, record).then((res) => {
                //Reset month to reload
                this.setMonth(this.state.currentMonth);
                return true;
              });
            }}
          />
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => {
          return <Button onClick={() => deleteRow(record)}>Delete</Button>;
        }
      }
    ];

    this.selectMonth = React.createRef();
    this.changedCompany = this.changedCompany.bind(this);
  }


  componentDidMount() {
   this.setMonth(new Date());
  }

  setMonth = (dt) => {
    if(dt.hasOwnProperty('_d')) dt = dt._d;
    API.getDataEntryRecords(dt, this.state.company).then((res) => {
      API.getDataEntryRecords(dt, this.state.company, "recurrring").then((resRecurring) => {
        console.log(resRecurring.rows)
        this.setState({
          currentMonth: dt,
          manualRows: res.rows,
          recurringRows: resRecurring.rows,
          company: this.state.company
        });
      });
    });
  }

  changedCompany(company) {
    API.getDataEntryRecords(this.state.currentMonth, company).then((res) => {
      API.getDataEntryRecords(this.state.currentMonth, company, "recurrring").then((resRecurring) => {
        this.setState({
          currentMonth: this.state.currentMonth,
          manualRows: res.rows,
          recurringRows: resRecurring.rows,
          company: company
        });
      });
    });
  }

  addManualRow = () => {
    API.addDataEntryRecord(this.state.currentMonth, this.state.company).then((res) => {
      //Reset month to reload
      this.setMonth(this.state.currentMonth);
    });
  }

  addRecurringRow = () => {
    API.addDataEntryRecord(this.state.currentMonth, this.state.company, "recurring").then((res) => {
      //Reset month to reload
      this.setMonth(this.state.currentMonth);
    });
  }

  render () {
      const { manualRows } = this.state;

      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

      return <div className="entry">
              <div className="dataTable">
                <h2>Manual Entries</h2>
                <Space direction="horizontal" size={12}>
                  <DatePicker
                  picker="month"
                  onChange={date => this.setMonth(date)}
                  defaultValue={moment()}
                  />
                  <Select style={{ width: '150px' }} value={this.state.company} onChange={this.changedCompany}>
                    <Option value="QL">Queensland</Option>
                    <Option value="VI">Victoria</Option>
                  </Select>
                </Space>
                <Button style={ {marginLeft: '10px'}}onClick={this.addManualRow}>Add Record</Button>
                <br/>
                <Table
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  pagination={false}
                  dataSource={this.state.manualRows}
                  columns={this.manualColumns}
                />
                <h2>Recurring Entries</h2>
                <Button style={ {marginLeft: '10px'}}onClick={this.addRecurringRow}>Add Record</Button>
                <Table
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  pagination={false}
                  dataSource={this.state.recurringRows}
                  columns={this.recurringColumns}
                />
            </div>
          </div>
  }

}
 

export default ARAPDataEntry;
