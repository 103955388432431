import { Component, useState} from "react";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faKey, faSignOutAlt, faFolder, faCog, faUser, faInfo, faProjectDiagram, faSync, faFile, faChartPie, faClipboard, faCoins, faClipboardCheck  } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from "react-router-dom";
import {ReactComponent as ICMLogo } from '../logo.svg';
import './Navbar.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as API from '../api/api';


const POWER_USER = 10;
const ADMIN = 3;

const HAS_READ = 0;
const HAS_WRITE = 1;
const HAS_READ_WRITE = 2;


function NavBar(props) {
    const [hasTender, setHasTender] = useState(props.user.tenderAllowed) 
    const [hasJob, setHasJob] = useState(props.user.jobAllowed)
    const [hasInfo, setHasInfo] = useState(props.user.infoAllowed)
    const [tenderAccess, setTenderAccess] = useState(props.user.tenderAccess)
    const [jobAccess, setJobAccess] = useState(props.user.jobAccess)
    const [infoAccess, setInfoAccess] = useState(props.user.infoAccess) 

    const getRouteClass = (path) => {
        return props.location.pathname.includes(path) ? 'navbarLinksSelected' : '';
    } 

    const syncData = () => {
        // API call the GET endpoint to sync the data
        API.dataSync();

        // Display Toast with Success & notice
        toast.success("Data Resync started... this may take a few minutes.");
    }

    const renderInfo = () => {
        switch (hasInfo) {
            case true:
                // do logic for checking permision
                switch (infoAccess) {
                    case HAS_READ:
                        return(
                            <>
                                <h2>Company Information</h2>
                                <Link to="/company_information" className={getRouteClass('/company_information')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faInfo} /> Company Information</span>
                                </Link>
                                <Link to="/coa" className={getRouteClass('/coa')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faCog} /> Chart of Accounts</span>
                                </Link>
                                <Link to="/finance/summary" className={getRouteClass('/finance/summary')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faCoins} /> Finance Summary</span>
                                </Link>
                            </> 
                        );
                    case HAS_WRITE:
                        return(
                            <>
                                <h2>Company Information</h2>
                                <Link to="/company_information" className={getRouteClass('/company_information')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faInfo} /> Company Information</span>
                                </Link>
                                <Link to="/coa" className={getRouteClass('/coa')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faCog} /> Chart of Accounts</span>
                                </Link>
                            </> 
                        );
                    case HAS_READ_WRITE:
                        return(
                            <>
                                <h2>Company Information</h2>
                                <Link to="/company_information" className={getRouteClass('/company_information')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faInfo} /> Company Information</span>
                                </Link>
                                <Link to="/coa" className={getRouteClass('/coa')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faCog} /> Chart of Accounts</span>
                                </Link>
                                <Link to="/finance/summary" className={getRouteClass('/finance/summary')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faCoins} /> Finance Summary</span>
                                </Link>
                            </>    
                        );
                    default:
                        return null;
                }
            case false:
                return null;
            default:
                return null;
        }
    }

    const renderTender = () => {
        switch (hasTender) {
            case true:
                // do logic for checking permission
                switch (tenderAccess) {
                    case HAS_READ:
                        return(
                            <>
                                <h2>Tender Section</h2>
                                <Link to="/job_cost_summary" className={getRouteClass('/job_cost_summary')}>
                                        <span><FontAwesomeIcon className={"navbarSvg"} icon={faChartPie} /> Tender Summary</span>
                                </Link>
                            </>
                        );
                    case HAS_WRITE:
                        return(
                            <>
                                <h2>Tender Section</h2>
                                <Link to="/job_cost_estimate" className={getRouteClass('/job_cost_estimate')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faFile} /> Tender Data Entry</span>
                                </Link>
                                <Link to="/job_cost_start_up" className={getRouteClass('/job_cost_start_up')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faClipboard} /> Tender Start Up</span>
                                </Link>
                            </>
                        );
                    case HAS_READ_WRITE:
                        return(
                            <>
                                <h2>Tender Section</h2>
                                <Link to="/job_cost_summary" className={getRouteClass('/job_cost_summary')}>
                                        <span><FontAwesomeIcon className={"navbarSvg"} icon={faChartPie} /> Tender Summary</span>
                                </Link>
                                <Link to="/job_cost_estimate" className={getRouteClass('/job_cost_estimate')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faFile} /> Tender Data Entry</span>
                                </Link>
                                <Link to="/job_cost_start_up" className={getRouteClass('/job_cost_start_up')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faClipboard} /> Tender Start Up</span>
                                </Link>
                                <Link to="/go-no-go" className={getRouteClass('/go-no-go')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faClipboardCheck} /> Tender Go-No-Go</span>
                                </Link>
                            </>
                        );
                    default:
                        return null;
                }
            case false:
                return null;
            default:
                return null;
        }
    }

    const renderJob = () => {
        switch (hasJob) {
            case true:
                // do logic for checking permision
                switch (jobAccess) {
                    case HAS_READ:
                        return(
                            <>
                                <h2>Job Section</h2>
                                <Link to="/jobs" className={getRouteClass('/jobs')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faProjectDiagram} /> Job Summary</span>
                                </Link>
                            </>
                        );
                    case HAS_WRITE:
                        return(
                            <>
                                <h2>Job Section</h2>
                                <Link to="/arap_entry" className={getRouteClass('/arap_entry')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faKey} /> AR/AP Data Entry</span>
                                </Link>
                            </>
                        );
                    case HAS_READ_WRITE:
                        return(
                            <>
                                <h2>Job Section</h2>
                                <Link to="/jobs" className={getRouteClass('/jobs')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faProjectDiagram} /> Job Summary</span>
                                </Link>
                                <Link to="/arap_entry" className={getRouteClass('/arap_entry')}>
                                    <span><FontAwesomeIcon className={"navbarSvg"} icon={faKey} /> AR/AP Data Entry</span>
                                </Link>
                            </>
                        );
                    default:
                        return null;
                }
            case false:
                return null;
            default:
                return null;
        }
    }

    const isSuperUser = () => {
        if (props.user.qld === POWER_USER || props.user.vic === POWER_USER) {
            return(
                <>
                    <h2>Admin</h2>
                    <Link to="/users" className={getRouteClass('/users')}><span><FontAwesomeIcon className={"navbarSvg"} icon={faUser} /> Manage Users</span></Link>
                    <Link to="/access" className={getRouteClass('/access')}><span><FontAwesomeIcon className={"navbarSvg"} icon={faCog} /> Manage User Access</span></Link>
                    <span className="fakeLink" onClick={syncData}><FontAwesomeIcon className={"navbarSvg"} icon={faSync} /> Force Data Sync</span>
                </>
            )
        } else if (props.user.qld === ADMIN || props.user.vic === ADMIN) {
            return(
                <>
                    <h2>Admin</h2>
                    <Link to="/users" className={getRouteClass('/users')}><span><FontAwesomeIcon className={"navbarSvg"} icon={faUser} /> Manage Users</span></Link>
                    <span className="fakeLink" onClick={syncData}><FontAwesomeIcon className={"navbarSvg"} icon={faSync} /> Force Data Sync</span>
                </>
            )
        } else {
            return null;
        }
    }

    return(
        <div className="dashboard">
            <ToastContainer/>
            <div className="navbar">
                <ICMLogo style={{padding: '20px'}}/>
                <div className="NavbarItems">
                    <div className="NavbarSection">
                        <h2>Home</h2>
                        <Link to="/dashboard" className={getRouteClass('/dashboard')}>
                            <span><FontAwesomeIcon className={"navbarSvg"} icon={faHome} /> Home</span>
                        </Link>
                    </div>
                    <div className="NavbarSection">
                        {renderInfo()}                      
                    </div>
                    <div className="NavbarSection">
                        {renderTender()}
                    </div>
                    <div className="NavbarSection">
                        {renderJob()}  
                    </div>
                    <div className="NavbarSection">
                        {isSuperUser()}
                    </div>
                    <div className="NavbarSection">
                        <a href="/logout"><span><FontAwesomeIcon className={"navbarSvg"} icon={faSignOutAlt} /> Logout</span></a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="navbarTop">
                    <h1>{props.title}</h1>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default withRouter(NavBar);
