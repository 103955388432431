import React, { useEffect, useState } from 'react'
import * as API from '../api/api';
import './JobCostEstimate.scss';
import { Table, TimePicker, Input, DatePicker, Select, Button, Popconfirm} from 'antd';

import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

export default function JobCostEstimate() {
    // Get/Set State Hooks:
    const [data, setData] = useState([]);
    const [projectSector, setProjectSector] = useState([]);
    const [submittedValue, setSubmittedValue] = useState([]);
    const [status, setStatus] = useState(["WIP", "Submitted", "Won", "Lost", "Other - Add Note"])
    const [categories, setCategories] = useState([]);

    // constant formats:
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'HH:mm:ss';
    const ConfText = "Are you sure you want to delete this tender?";

    // useEffect retrieves API Data.
    useEffect(() => {
        API.getAllTenders().then((res) => {
            // console.log(res.rows);
            console.log(res.rows);
            setData(res.rows);
        })

        // Map TenderSector API call to state array.
        const tempSector = [];
        const tempSubValue = [];
        const tempCategories = [];

        API.getTenderSector().then(res => {
            res.sectors.map(value => {
                // push each sector to array for state storage.
                    tempSector.push(value.type)
                    // send each sector to API for collecting tender value.
                    API.getTenderValueViaType(value.type).then((res) => {
                        Object.keys(res).map((col) => {
                            let sum = parseFloat(res[col].sum);
                            if(isNaN(sum)) sum = 0.0;
                            // push scrubbed total value to array for state storage.
                            tempSubValue.push(sum);
                        })
                    })
            })
        })

        setProjectSector(tempSector);
        setSubmittedValue(tempSubValue);

        API.getTenderCategory().then(res => {
            
            Object.keys(res.cat).map(categories => {
                tempCategories.push(res.cat[categories].category);
            })
            setCategories(tempCategories);
        })

    }, []);

    const createTender = () => {
        API.addTender(' ', ' ', 'WIP', moment().format(dateFormat), moment().format(dateFormat)).then((res) => {
            updateRenderedData();
        })
        console.log("created!");
    }
    const updateRenderedData = () => {
        API.getAllTenders().then((res) => {
            setData(res.rows);
        })
    }

    const cols = [
        {title: "Project Code", dataIndex: "projectCode", key: "projectCode", fixed: 'left', width: 100,
        sortDirections: ["ascend"], 
        defaultSortOrder: ["ascend"],
        sorter: (a, b) => a.projectCode.length - b.projectCode.length,
            render: (_, record) => (
                <Input
                    key={record.id + "projectCode"}
                    defaultValue={record.projectCode}
                    placeholder='Input Project Code'
                    onChange={(inputStr) => {
                        record.projectCode = inputStr.target.value;
                        // Call UPDATE API to data in DB.
                        API.updateTender(record.id, record).then((res) => {
                            // Call GET API to refresh the current rendered data.
                            // updateRenderedData();
                        })
                    }}
                />
            )
        },
        {title: "Project Name", dataIndex: "projectName", key: "projectName", width: 150,
            render: (_, record) => (
                <Input
                    key={record.id + "projectName"}
                    defaultValue={record.projectName}
                    placeholder='Input Project Name'
                    onChange={(inputStr) => {
                        record.projectName = inputStr.target.value;
                        // Call UPDATE API to data in DB.
                        API.updateTender(record.id, record).then((res) => {
                            // Call GET API to refresh the current rendered data.
                            // updateRenderedData();
                        })
                    }}
                />
            )
        },
        {title: "Project Sector", dataIndex: "type", key: "type", width: 150,
            render: (_, record) => (
                <Select 
                    key={data.id + "type"} 
                    defaultValue={record.type}
                    style={{minWidth: 100}} 
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                        record.type = value;
                        // Call UPDATE api to update status in DB.
                        API.updateTender(record.id, record).then((res) => {
                            // Call GET API to refresh the current rendered data.
                            // updateRenderedData();
                            return true;
                        })
                    }}
                    
                >
                    {projectSector.map((val) => {
                        return(
                            <Option value={val}>{val}</Option>
                        )
                    })}
                </Select>
            )
        },
        {title: "Due Date", dataIndex: "dueDate", key: "dueDate", width: 150,
            render: (_, record) => (
                <DatePicker
                    key={record.id + "dueDate"}
                    defaultValue={() => {
                        if (record.dueDate === null) {
                            return null;
                        } else {
                            return moment(record.dueDate)
                        }
                    }}
                    placeholder={"Due Date"}
                    format={dateFormat}
                    onChange={(date, dateStr) => {
                        record.dueDate = dateStr;
                        API.updateTender(record.id, record).then((res) => {
                            return true;
                        })
                        
                        }
                    }
                    allowClear={false}
                />
            )
        },
        {title: "Project Status", dataIndex: "status", key: "status", width: 150, editable: true,
        filters: status.map(text => {return {text: text, value: text}}),
        onFilter: (value, record) => {
            return record.status && record.status.includes(value);
        },
            render: (_, record) => (
                <Select 
                    key={data.id + "status"} 
                    defaultValue={record.status}
                    style={{minWidth: 100}}
                    onChange={(value) => {
                        record.status = value;
                        // Call UPDATE api to update status in DB.
                        API.updateTender(record.id, record).then((res) => {
                            // Call GET API to refresh the current rendered data.
                            // updateRenderedData();
                            return true;
                        })
                    }}    
                >
                    <Option value="WIP">WIP</Option>
                    <Option value="Submitted">Submitted</Option>
                    <Option value="Won">Won</Option>
                    <Option value="Lost">Lost</Option>
                    <Option value="Withdrawn">Withdrawn</Option>
                    <Option value="Other - Add Note">Other - Add Note</Option>
                </Select>
            )
        },
            {title: "Project Category", dataIndex: "projectCategory", key: "projectCategory", width: 150,
                render: (_, record) => (
                    <Select
                        key={record.id + "projectCategory"}
                        defaultValue={record.projectCategory}
                        style={{minWidth: 100}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(value) => {
                            record.projectCategory = value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                                return true;
                            })
                        }}
                    >
                        {categories.map((val) => {
                            return(
                                <Option value={val}>{val}</Option>
                            )
                        })}
                    </Select>

                )
            },
            {title: "Allocated", dataIndex: "allocated", key: "allocated", width: 150,
                render: (_, record) => (
                    <Input 
                        key={data.id + "allocated"} 
                        defaultValue={record.allocated}
                        onChange={(inputStr) => {
                            record.allocated = inputStr.target.value;
                            // Call UPDATE api to update status in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                                return true;
                            })
                        }}
                        
                    />
                )
            },
            {title: "Due Time", dataIndex: "dueTime", key: "dueTime", width: 150,
                render: (_, record) => (
                    <TimePicker
                        key={record.id + "dueTime"}
                        defaultValue={() => {
                            if (record.dueTime === null) {
                                return null;
                            } else {
                                return moment();
                            }
                        }}
                        placeholder={"Due Time"}
                        onChange={(time, timeStr) => {
                            record.dueTime = timeStr;
                            API.updateTender(record.id, record).then((res) => {
                                console.log("Call new GET req.")
                                return true;
                            })
                        }}
                        format={timeFormat}
                        
                    />
                )
            },
            {title: "Submitted Value", dataIndex: "submittedValue", key: "submittedValue", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "submittedValue"}
                        defaultValue={record.submittedValue}
                        placeholder='Input Value'
                        onChange={(inputStr) => {
                            record.submittedValue = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Re-Submitted Value", dataIndex: "resubmittedValue", key: "resubmittedValue", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "resubmittedValue"}
                        defaultValue={record.resubmittedValue}
                        placeholder='Input Value'
                        onChange={(inputStr) => {
                            record.resubmittedValue = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Submitted Date", dataIndex: "submittedDate", key: "submittedDate", width: 150,
                render: (_, record) => (
                    <DatePicker
                        key={record.id + "submittedDate"}
                        defaultValue={() => {
                            if (record.submittedDate === null) {
                                return null;
                            } else {
                                return moment(record.submittedDate);
                            }
                        }}
                        placeholder={"Submitted Date"}
                        format={dateFormat}
                        onChange={(date, dateStr) => {
                            record.submittedDate = dateStr;
                            API.updateTender(record.id, record).then((res) => {
                                console.log("Call new GET req.")
                                return true;
                            })
                            
                            }
                        }
                        allowClear={false}
                    />
                )
            },
            {title: "Proposed Margin", dataIndex: "proposedMargin", key: "proposedMargin", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "proposedMargin"}
                        defaultValue={record.proposedMargin}
                        placeholder='Input Margin'
                        onChange={(inputStr) => {
                            record.proposedMargin = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "GFA", dataIndex: "gfa", key: "gfa", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "gfa"}
                        defaultValue={record.gfa}
                        placeholder='Input GFA'
                        onChange={(inputStr) => {
                            record.gfa = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Rate Per", dataIndex: "ratePer", key: "ratePer", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "ratePer"}
                        defaultValue={record.ratePer}
                        placeholder='Input Rate'
                        onChange={(inputStr) => {
                            record.ratePer = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Re-Submitted Rate Per", dataIndex: "resubRatePer", key: "resubRatePer", width: 150,
                render: (_, record) => (
                    <Input
                        key={record.id + "resubRatePer"}
                        defaultValue={record.resubRatePer}
                        placeholder='Input Rate'
                        onChange={(inputStr) => {
                            record.resubRatePer = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Notes", dataIndex: "notes", key: "notes", width: 250,
                render: (_, record) => (
                    <TextArea
                        key={record.id + "notes"}
                        defaultValue={record.notes}
                        placeholder='Input Notes (225 char limit)'
                        rows={3}
                        maxLength={225}
                        onChange={(inputStr) => {
                            record.notes = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Feedback", dataIndex: "feedback", key: "feedback", width: 250,
                render: (_, record) => (
                    <TextArea
                        key={record.id + "feedback"}
                        defaultValue={record.feedback}
                        placeholder='Input Feedback (225 char limit)'
                        rows={3}
                        maxLength={225}
                        onChange={(inputStr) => {
                            record.feedback = inputStr.target.value;
                            // Call UPDATE API to data in DB.
                            API.updateTender(record.id, record).then((res) => {
                                // Call GET API to refresh the current rendered data.
                                // updateRenderedData();
                            })
                        }}
                    />
                )
            },
            {title: "Actions", dataIndex: "actions", key: "actions", fixed: 'right', width: 100,
            render: (_, record) => (
                <Popconfirm
                    title={ConfText}
                    onConfirm={() => {
                        API.deleteTender(record.id).then(updateRenderedData());
                    }}
                    okText="Yes"
                    cancelText="Cancel"    
                >
                    <Button>Delete</Button>
                </Popconfirm>
                
            )
        },
    ]

    return(
        <div className='entry'>
            <div className='dataTable'>
                <h2>Tender Entry</h2>
                <Button style={ {marginLeft: '10px'}} onClick={createTender}>Add Tender</Button>
                <Table
                    columns={cols}
                    className={'entryTable'}
                    dataSource = {data}
                    pagination= {{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']} }
                    rowClassName={() => 'editable-row'}
                    rowKey={row => row.id}
                    bordered
                    size="small"
                    scroll={{ x: 'calc(800px + 50%)', y: 550 }}
                />
            </div>
        </div>
        
    )
}