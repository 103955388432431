import React, {useEffect, useState, useLayoutEffect} from "react";
import { Row, Col, DatePicker, Button } from "antd";
import * as API from "../../api/api";
import moment from "moment";

// Component Imports:
import FinancialStatistic from "../../components/FinancialStatistic";

// Style Sheet Imports:
import './FiscalSummary.scss';

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
var formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
  
	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

export default function FiscalSummary() {
	const [SecuredRevenue, setSecuredRevenue] = useState(0);
	const [CostsCommited, setCostsCommited] = useState(0);
	const [costClaimsSummary, setCostClaimsSummary] = useState({});
	const [generalFigures, setGeneralFigures] = useState({});
	const [startPeriod, setStartPeriod] = useState(moment().subtract(1, 'month').startOf('month'));
	const [endPeriod, setEndPeriod] = useState(moment().add(1, 'month').endOf('month'));
	const [isLoadedCC, setIsLoadedCC] = useState(false);
	const [isLoadedGF, setIsLoadedGF] = useState(false);
	const [secRev, setSecRev] = useState(0);
	const [updatedSecRev, setUpdatedSecRev] = useState(0);

	// Get Total Secured Revenue and Costs Committed:
	useEffect(() => { 
		API.getReportData("year", startPeriod.format(dateFormat), endPeriod.format(dateFormat), "AA").then(res => {
			setSecuredRevenue(res.SecuredRevenue);
			setCostsCommited(res.CostsCommited);
		});		
	}, []);

	// // Get Cost Claims Summary (Secured Revenue and Costs Committed via Fiscal Year):
	useLayoutEffect(() => {
		let abortController = new AbortController();
		API.getTotalCostClaimsSummary('QL').then(res => {
			setCostClaimsSummary(res);
			setIsLoadedCC(true);
		});
		return () => abortController.abort();
	}, []);

	// // Get General Figures (TYD Revenue, YTD CoS, YTD Expenses, Net Income (Total)):
	useEffect(() => { 
		API.getReportData("year", startPeriod.format(dateFormat), endPeriod.format(dateFormat), "AA").then(res => { 
			setGeneralFigures(res.generalFigures);
			setIsLoadedGF(true);
		});
	}, [])

	const handlePickerChange = (date, dateString) => {
		setStartPeriod(dateString[0]);
		setEndPeriod(dateString[1]);
	}

  	return (
		<div className="container">
			<h3 className="title">Financial Summary</h3>
			<RangePicker className='date-picker' picker="day" onChange={handlePickerChange} format={dateFormat} value={[startPeriod, endPeriod]}/>
			<Row style={{ marginTop: "20px", marginBottom: "20px" }}>
				<Col span={12}>
					<Row style={{ marginRight: "8px" }}>
						<FinancialStatistic
						title={"Secured Revenue"}
						value={SecuredRevenue}
						colour="VibrantGreen"
						/>
					</Row>
				</Col>
				<Col span={12}>
					<Row style={{ marginLeft: "8px" }}>
						<FinancialStatistic
						title={"Costs Committed"}
						value={CostsCommited}
						colour="VibrantRed"
						/>
					</Row>
				</Col>
				
				<Col span={12}>
				{isLoadedCC && Object.keys(costClaimsSummary.revData).map((val) => {
					return (
						<Row key={val} style={{ marginRight: "8px", marginTop: "16px" }}>
							<FinancialStatistic
							title={`Secured Revenue for ${val}`}
							value={costClaimsSummary.revData[val].summary}
							colour="VibrantGreen"
							/>
						</Row>
					);
				})}
				</Col>
				<Col span={12}>
				{isLoadedCC && Object.keys(costClaimsSummary.costData).map((val) => {
					return (
						<Row key={val} style={{ marginLeft: "8px", marginTop: "16px" }}>
							<FinancialStatistic
							title={`Cost Committed for ${val}`}
							value={costClaimsSummary.costData[val].summary}
							colour="VibrantRed"
							/>
						</Row>
					);
				})}
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
				{isLoadedGF && 
				<>
					<Col span={6}>
						<FinancialStatistic
							title={"YTD Revenue"}
							value={generalFigures.Revenue}
							financialYear="(FY2021/22)"
							colour="Blue"
						/>
					</Col>
					<Col span={6}>
						<FinancialStatistic
							title={"YTD Cost of Sales"}
							value={generalFigures.COGS}
							margin={generalFigures.Percentages.COGS}
							financialYear="(FY2021/22)"
							colour="Red"
						/>
					</Col>
					<Col span={6}>
						<FinancialStatistic
							title={"YTD Expenses"}
							value={generalFigures.Expense}
							margin={generalFigures.Percentages.Expense}
							financialYear="(FY2021/22)"
							colour="Yellow"
						/>
					</Col>
					<Col span={6}>
						<FinancialStatistic
							title={"Net Income"}
							value={generalFigures.Income}
							margin={generalFigures.Percentages.Income}
							financialYear="(FY2021/22)"
							colour="Green"
						/>
					</Col>
				</> }
			</Row>
			<Row style={{ marginTop: "20px", marginBottom: "20px" }}>
				{
					isLoadedGF && isLoadedCC && Object.keys(costClaimsSummary.revData).map((val) => {
						const totalRevenue = Number(costClaimsSummary.revData[val].summary.replace(/[^0-9.-]+/g,"")) + Number(generalFigures.Revenue.replace(/[^0-9.-]+/g,""));
						const totalCost = Number(costClaimsSummary.costData[val].summary.replace(/[^0-9.-]+/g,"")) + Number(generalFigures.COGS.replace(/[^0-9.-]+/g,""));
						const netSum = totalRevenue - totalCost - Number(generalFigures.Expense.replace(/[^0-9.-]+/g,""));
						return (
							<Col key={val} span={12} gutter={[16, 16]}>
								<Row style={{margin: '8px', marginTop: '0px'}}>
									<FinancialStatistic title={`Net Income for ${val}`} value={formatter.format(netSum)} colour="VibrantPurple" />
								</Row>
							</Col>
						)
					})
				}	
			</Row>
		</div>
  	);
};
