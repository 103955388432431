import React, { useContext, useState, useEffect, useRef, Component }  from "react";
import './ChartOfAccounts.scss';
import { Select, Table, Input, Form, Row, Col } from "antd";
import 'antd/dist/antd.css';
import * as API from '../api/api';
const { Option } = Select;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class ChartOfAccounts extends Component {

  constructor(props) {
    super();
    this.state = {
      company: 'QL',
      accounts: [],
      balanceSheetTypes: [],
      comparitiveMappingTypes: [], 
    };

    this.columns = [
        {
          title: 'Account Number',
          dataIndex: 'account_ref',
          key: 'account_ref'
        },
        {
          title: 'Account Name',
          dataIndex: 'account_name',
          key: 'account_name'
        },
        {
          title: 'Type',
          dataIndex: 'statement_type',
          key: 'statement_type',
        },
        {
          title: 'Balance Sheet & Income Statement Mapping',
          dataIndex: 'balance_sheet_mapping',
          key: 'balance_sheet_mapping',
          render: (_, record) =>
            this.state.accounts.length >= 1 ? (
              <Select style={{ width: '300px' }} value={record.balance_sheet_mapping} showSearch optionFilterProp="children" onChange={(update) => {
                this.handleUpdate(record.account_ref, {balance_sheet_mapping: update});
              }}>
                {this.state.balanceSheetTypes.map(balanceSheetType => <Option value={balanceSheetType.id}>{balanceSheetType.display}</Option>)}
              </Select>
            ) : null
        },
        {
          title: 'Comparative Mapping',
          dataIndex: 'comparitive_mapping',
          key: 'comparitive_mapping',
          render: (_, record) =>
            this.state.accounts.length >= 1 ? (
              <Select style={{ width: '200px' }} value={record.comparitive_mapping} showSearch optionFilterProp="children" onChange={(update) => {
                this.handleUpdate(record.account_ref, {comparitive_mapping: update});
              }}>
                {this.state.comparitiveMappingTypes.map(comparativeMapping => <Option value={comparativeMapping.id}>{comparativeMapping.display}</Option>)}
              </Select>
            ) : null
        },
        {
          title: 'Bank Summary',
          dataIndex: 'summary_display',
          render: (_, record) =>
            this.state.accounts.length >= 1 ? (
              <Select style={{ width: '150px' }} value={record.summary_display ? 'Y' : 'N'} onChange={(update) => {
                this.handleUpdate(record.account_ref, {summary_display: update});
              }}>
                <Option value="Y">Yes</Option>
                <Option value="N">No</Option>
              </Select>
            ) : null
        },
        {
          title: 'Account Watchlist',
          dataIndex: 'watchlist_display',
          render: (_, record) =>
            this.state.accounts.length >= 1 ? (
              <Select style={{ width: '150px' }} value={record.watchlist_display ? 'Y' : 'N'} onChange={(update) => {
                this.handleUpdate(record.account_ref, {watchlist_display: update});
              }}>
                <Option value="Y">Yes</Option>
                <Option value="N">No</Option>
              </Select>
            ) : null
        },
        ,
        {
          title: 'Bank Balance',
          dataIndex: 'bank_balance',
          render: (_, record) =>
            this.state.accounts.length >= 1 ? (
              <Select style={{ width: '150px' }} value={record.bank_balance ? 'Y' : 'N'} onChange={(update) => {
                this.handleUpdate(record.account_ref, {bank_balance: update});
              }}>
                <Option value="Y">Yes</Option>
                <Option value="N">No</Option>
              </Select>
            ) : null
        },
      ];


    this.changedCompany = this.changedCompany.bind(this);
    this.getSettingsData = this.getSettingsData.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.getSettingsData();
  }

  changedCompany(company) {
    this.setState({
      ...this.state,
      company
    });
    this.getSettingsData(company);
  }

  handleUpdate(id, record) {
      if(record.hasOwnProperty('watchlist_display')) {
        API.updateCompanyAccountWatchlist(this.state.company, id, record.watchlist_display).then(res => {
            this.getSettingsData();
        });
      } else if(record.hasOwnProperty('bank_balance')) {
        API.updateCompanyAccountBankBalance(this.state.company, id, record.bank_balance).then(res => {
          this.getSettingsData();
      });
      } else if(record.hasOwnProperty('balance_sheet_mapping')){
        API.updateCompanyAccountBalanceSheetMapping(this.state.company, id, record.balance_sheet_mapping).then(res => {
          this.getSettingsData();
        });
      } else if(record.hasOwnProperty('comparitive_mapping')){
        API.updateCompanyAccountComparativeMapping(this.state.company, id, record.comparitive_mapping).then(res => {
          this.getSettingsData();
        });
      } else {
        API.updateCompanyAccountSummary(this.state.company, id, record.summary_display).then(res => {
            this.getSettingsData();
        });
      }
  }

  getSettingsData(company = this.state.company) {
    API.getChartOfAccounts(company).then((res) => {
        this.setState({
            ...this.state,
            accounts: res.chartOfAccounts,
            balanceSheetTypes: res.balanceSheetTypes,
            comparitiveMappingTypes: res.comparitiveMappingTypes,
        });
    })
  }

  render () {
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    return <div className="ChartOfAccounts">
        <div className="chartOptions">
            <Select style={{ width: '100%' }} value={this.state.company} onChange={this.changedCompany}>
                {<Option value="QL">Queensland</Option>}
                {<Option value="VI">Victoria</Option>}
            </Select>
        </div>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div className="card">
                    <h2>Chart of Accounts</h2>
                    <Table
                        components={components}
                        rowClassName={(record) => (record.balance_sheet_mapping || record.comparitive_mapping) ? '' : 'missingDataRow'}
                        bordered
                        dataSource={this.state.accounts}
                        columns={this.columns}
                    />
                </div>
            </Col>
        </Row>
  </div>
  }

}
 

export default ChartOfAccounts;
