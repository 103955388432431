import { Component } from "react";
import { Redirect } from "react-router-dom";
import React from 'react';
import {ReactComponent as ICMLogo } from '../logo.svg';
import * as API from '../api/api';
import './Landing.scss';

export default class Landing extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            redirect: null
        };

        this.loginUser = this.loginUser.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    loginUser() {
        if(!this.state.email || !this.state.password) {
            this.setState({errorMessage: 'Please enter a valid email and password.'});
        } else {
            API.loginUser(this.state.email, this.state.password).then(res => {
                if(!res) {
                    this.setState({errorMessage: 'Invalid username or password.'});
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: "/dashboard" });
                    }, 1000);
                }
            });
        }
    }

    changeEmail(email) {
        this.setState({ email: email.target.value });
    }

    changePassword(password) {
        this.setState({ password: password.target.value });
    }

    render () {
        if(this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return <div className="landingPage">
            <div className="infoSection">
                <ICMLogo style={{width: '300px', padding: '10px'}}/>
                <p>Welcome to the <b>ICM Reporting Portal.</b></p>
                <p>If you're an internal user, use <b>Sign in with Microsoft</b> below.</p>
                <br/>
                <a href="/login">Sign in with Microsoft</a>
                <hr/>
                <div className="emaillPass">
                    <input type="text" placeholder="Email address" onChange={this.changeEmail}/><br/>
                    <input type="password" placeholder="Password" onChange={this.changePassword}/><br/>
                    <p className="errMsg">{this.state.errorMessage}</p>
                    <a onClick={this.loginUser}>Sign in with Email</a>
                </div>
            </div>
        </div>
    }
 }

