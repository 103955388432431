import { Input, Form, Select, DatePicker, Button, Modal, InputNumber } from "antd";
import React, {createRef, useEffect, useState} from "react";
import './TenderStartUp.scss';
import * as API from "../api/api";
import moment from "moment";

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

export default function TenderStartUp() {
    const [form] = Form.useForm();
    const [isVisible, setIsVisible] = useState(false)
    const [sectors, setSectors] = useState([]);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        API.getTenderSector().then((res) => {
            setSectors(res.sectors)
        })
    }, [])

    useEffect(() => {
        API.getTenderCategory().then((res) => {
            setCategory(res.cat)
        })
    }, [])

    const onTenderSectChange = (value) => {
        switch (value) {
            case sectors.map(value => {
                return value.type
            }): 
                form.setFieldsValue({ 
                    tenderType: sectors.map(value => {
                        return value.type
                    }) 
                });
                return;
        }
    }

    const onValueChange = (changedValues, allValues) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName === "portion1" || fieldName === "portion2" || fieldName === "portion3" || fieldName === "portion4" || fieldName === "portion5" || fieldName === "portion6") {
            const portion1 = changedValues["portion1"] || allValues["portion1"] || "";
            const portion2 = changedValues["portion2"] || allValues["portion2"] || "";
            const portion3 = changedValues["portion3"] || allValues["portion3"] || "";
            const portion4 = changedValues["portion4"] || allValues["portion4"] || "";
            const portion5 = changedValues["portion5"] || allValues["portion5"] || "";
            const portion6 = changedValues["portion6"] || allValues["portion6"] || "";

            const total = (portion1) + (portion2) + (portion3) + (portion4) + (portion5) + (portion6)
            
            form.setFieldsValue({ totalPortion: total })
        }

        if (fieldName === "totalPortion") {
            const totalPortion = changedValues["totalPortion"] || allValues["totalPortion"] || "";

            form.setFieldsValue({ estimatedGFA: parseInt(totalPortion) })
        }

        
    }

    const onTenderTypeChange = (value) => {
        switch (value) {
            case category.map(value => {
                return value.category
            }): 
                form.setFieldsValue({ 
                    tenderType: category.map(value => {
                        return value.category
                    }) 
                });
                return;
        }
    }

    const onSubmit = (values) => {
        setIsVisible(true);
        API.addTender(values.projectName, values.projectCode, 'WIP', moment(values.tenderDueDate).format(dateFormat), moment().format(dateFormat)).then(form.resetFields());
        API.addStartupReport(values)
        form.resetFields();
    }

    return (
        <div className="form-container">
            <Form
                form = {form}
                name = "tenderStartUp"
                onFinish = {onSubmit}
                onValuesChange={onValueChange}
                className="form-body"
            >
                <div className="form-title">
                    <h2>Create New Tender Start Up</h2>
                </div>
                <Form.Item
                    className="form-item"
                    label="Project Name"
                    name="projectName"
                    rules={[{ required: true, message: 'Please input your project name!' }]}
                >
                    <Input placeholder="Project name"/>    
                </Form.Item>       
                <Form.Item
                    className="form-item"
                    label="Project Code"
                    name="projectCode"
                    rules={[{ required: true, message: 'Please input your project code!' }]}
                >
                    <Input placeholder="Project code"/>    
                </Form.Item>          
                <Form.Item
                    className="form-item"
                    label="Project Sector"
                    name="projectSector"
                    rules={[{ required: true}]}
                >
                    <Select 
                        style={{width: 280}}
                        placeholder="Select Project Sector"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={onTenderSectChange}
                        allowClear
                    >
                        {sectors.map(value => {
                            return(
                                <Option key={value.type} value={value.type}>{value.type}</Option>
                            )
                        })}
                    </Select>   
                </Form.Item> 
                <Form.Item
                    className="form-item"
                    label="Tender Type"
                    name="tenderType"
                    rules={[{ required: true}]}
                >
                    <Select
                        style={{width: 280}}
                        placeholder="Select Tender Type"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={onTenderTypeChange}
                    >
                        {category.map(value => {
                            return(
                                <Option key={value.category} value={value.category}>{value.category}</Option>
                            )
                        })}
                    </Select>    
                </Form.Item> 
                <Form.Item
                    className="form-item"
                    label="Tender Received Date"
                    name="tenderReceivedDate"

                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Tender Due Date"
                    name="tenderDueDate"
                    rules={[{ required: true, message: 'Please input your tender due date!' }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Work Commencement Date"
                    name="workCommencementDate"
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Practical Completion Date"
                    name="practicalCompletionDate"
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Site Visit Date"
                    name="siteVisitDate"
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Public Liability"
                    name="publicLiability"
                    rules={[{ required: true, message: 'Please input your public liability!' }]}
                >
                    <Input  placeholder="Public liability"/>
                </Form.Item>
                <Form.Item
                    className="form-item"
                    label="Professional Indemnity"
                    name="professionalIndemnity"
                    rules={[{ required: true, message: 'Please input your professional indemnity!' }]}
                >
                    <Input placeholder="Professional Indemnity"/>
                </Form.Item>
                <Form.Item name="submissionWeighting" label="Submission Weighting">
                    <Form.Item className="form-item" name="pricing" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Pricing %" />
                    </Form.Item>
                    <Form.Item className="form-item" name="experience" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Experience %" />
                    </Form.Item>
                    <Form.Item className="form-item" name="capacity" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Capacity %" />
                    </Form.Item>
                    <Form.Item className="form-item" name="program" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Program %" />
                    </Form.Item>
                    <Form.Item className="form-item" name="methodology" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Methodology %" />
                    </Form.Item>
                    <Form.Item className="form-item" name="other" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <Input placeholder="Other %" />
                    </Form.Item>
                </Form.Item>
                <Form.Item className="form-item" label="Project Positives" name="projectPositives">
                    <Input.TextArea placeholder="Project positives" rows={4} />
                </Form.Item>
                <Form.Item className="form-item" label="Project Negatives" name="projectNegatives">
                    <Input.TextArea placeholder="Project negatives" rows={4} />
                </Form.Item>
                <Form.Item className="form-item" name="estimatedGFA" label="Estimated GFA">
                    <Form.Item className="form-item" name="portion1" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 1 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="portion2" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 2 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="portion3" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 3 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="portion4" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 4 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="portion5" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 5 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="portion6" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber style={{width: 280}} placeholder="Portion 6 (Value)" />
                    </Form.Item>
                    <Form.Item className="form-item" name="totalPortion" style={{display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}>
                        <InputNumber readOnly style={{width: 280}} placeholder="Total Portion (Calculated)" />
                    </Form.Item>
                </Form.Item>
                <Form.Item className="form-item" wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                    Submit
                    </Button>
                    
                    <Modal title="Tender Start Up Form" visible={isVisible} onOk={() => {setIsVisible(false)}} onCancel={() => {setIsVisible(false)}}>
                        <p>Your tender start up form has been successfully submitted.</p>
                    </Modal>
                </Form.Item>
            </Form>
        </div>
    )
} 